import React from "react";
import { useSelector } from "react-redux";
import { Trash, PencilSquare } from "react-bootstrap-icons";
import { Dropdown } from "react-bootstrap";

const StoreEntryDisp = ({
	storeEntry,
	setShowModal,
	setType,
	setStoreEntry,
	Items,
	setStoreEntryItem,
}) => {
	const Creds = useSelector((state) => state.cred.creds);

	const FindUser = (linker) => {
		let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

		return cred || { firstname: "unknown" };
	};

	const FindItem = (linker) => {
		let Item = Items.find((item) => parseInt(item.linker) === parseInt(linker));
		return Item || { name: "unknown" };
	};

	return (
		<tr className="item">
			<td>
				{FindItem(storeEntry.itemLinker).name}{" "}
				{storeEntry.live != 1 ? (
					<small
						className="text-success align-text-bottom text"
						style={{ fontSize: 8 }}
					>
						<br />
						<em>offline</em>
					</small>
				) : null}
			</td>
			<td>{storeEntry.type}</td>
			<td>
				<Dropdown variant="transparent">
					<Dropdown.Toggle variant="transparent" className="text-left">
						{storeEntry.supplier}
					</Dropdown.Toggle>
					<Dropdown.Menu variant="transparent">
						{storeEntry.details}
					</Dropdown.Menu>
				</Dropdown>
			</td>

			<td>{storeEntry.amount}</td>
			<td>
				{storeEntry.type === "issued" ||
				storeEntry.type === "return-received" ||
				storeEntry.type === "damaged" ? (
					<span className="text-danger">-{storeEntry.quantity}</span>
				) : (
					<span className="text-success">+{storeEntry.quantity}</span>
				)}
			</td>
			<td>{storeEntry.total}</td>
			<td>
				{new Date(parseInt(storeEntry.linker)).toLocaleDateString("en-US")}
			</td>
			<td>{FindUser(storeEntry.credLinker).email}</td>
			<td>
				<PencilSquare
					onClick={() => {
						setShowModal(true);
						setType("edit");
						setStoreEntry({ ...storeEntry });
						setStoreEntryItem(FindItem(storeEntry.itemLinker));
					}}
					style={{ cursor: "pointer" }}
					className="text-info mx-2"
				/>

				<Trash
					onClick={() => {
						setShowModal(true);
						setType("delete");
						setStoreEntry({ ...storeEntry });
						setStoreEntryItem(FindItem(storeEntry.itemLinker));
					}}
					style={{ cursor: "pointer" }}
					className="text-danger mx-2"
				/>
			</td>
		</tr>
	);
};

export default StoreEntryDisp;
