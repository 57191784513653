import { openDB } from "idb";

const DB_NAME = "school-db";
const DB_VERSION = 1;
const STORES = [
	"actives",
	"activities",
	"agents",
	"books",
	"credentials",
	"exams",
	"expensecats",
	"expenses",
	"grades",
	"insts",
	"intervals",
	"invoices",
	"lessons",
	"librarycats",
	"modes",
	"notifications",
	"onlinepayments",
	"onlines",
	"payments",
	"payrollcats",
	"payrollEntries",
	"payrollItems",
	"storecats",
	"storeentries",
	"storeitems",
	"structures",
	"subjects",
	"subs",
	"users",
];

// Open IndexedDB and create object stores if not exist
async function openDatabase() {
	return openDB(DB_NAME, DB_VERSION, {
		upgrade(db) {
			STORES.forEach((store) => {
				if (!db.objectStoreNames.contains(store)) {
					db.createObjectStore(store, { keyPath: "linker" }); // "linker" is primary key
				}
			});
		},
	});
}

// Save or update data in a specific store
export async function saveToIDB(store, linker, data) {
	const db = await openDatabase();
	await db.put(store, { ...data, linker: String(linker) }); // Overwrites existing entry if linker matches
}

// Get all data from a store
export async function getAllFromIDB(store) {
	const db = await openDatabase();
	return db.getAll(store);
}

// 🚀 Fetch & Filter Data Conditionally
export async function getFilteredFromIDB(store, filterFn) {
	const db = await openDatabase();
	const allData = await db.getAll(store);
	return allData.filter(filterFn);
}

// Remove an entry by linker
export async function removeFromIDB(store, linker) {
	const db = await openDatabase();
	await db.delete(store, linker);
}
