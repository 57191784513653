import React, { useState, useEffect } from "react";
import PayrollItemCrud from "./PayrollItemCrud";
import { useDispatch, useSelector } from "react-redux";
import {
	PlusCircle,
	Trash,
	PencilSquare,
	ArrowBarLeft,
	Book,
	BookmarkPlus,
	CurrencyDollar,
} from "react-bootstrap-icons";
import NoPayrollItems from "./NoPayrollItems";
import { FetchPayrollCats } from "../../../../Network/PayrollCatApi";
import GeneratePdf from "../../../../Utils/GeneratePdf";
import { useParams, useNavigate } from "react-router-dom";
import { FetchPayrollItems } from "../../../../Network/PayrollItemApi";
import GeneratePayslip from "../StaffPaySlip/GeneratePayslip";
import { FetchPayrollEntries } from "../../../../Network/PayrollEntryApi";
import PaySlips from "../StaffPaySlip/PaySlips";

const PayrollItems = () => {
	useEffect(() => {
		FetchPayrollCats(User.token, ActiveCred.instLinker, dispatch, online);
		FetchPayrollItems(User.token, ActiveCred.instLinker, dispatch, online);
		FetchPayrollEntries(User.token, ActiveCred.instLinker, dispatch, online);
	}, []);

	const params = useParams();
	const navigate = useNavigate();
	const [ShowModal, setShowModal] = useState(false);
	const [PayslipModal, setPayslipModal] = useState(false);
	const [Item, setItem] = useState({ catLinker: "", amount: "", details: "" });
	const [Type, setType] = useState("add");
	const [ViewPaySlips, setViewPaySlips] = useState(false);

	//redux dispatch
	const dispatch = useDispatch();

	const online = useSelector((state) => state.online.online);
	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Cats = useSelector((state) => state.payrollCat.payrollCats).filter(
		(item) => parseInt(item.deleted) === 0
	);
	const Items = useSelector((state) => state.payrollItem.payrollItems).filter(
		(item) =>
			parseInt(item.deleted) === 0 &&
			parseInt(item.staffLinker) === parseInt(params.staffLinker)
	);

	const GetCat = (catLinker) => {
		let cat = Cats.find((cat) => parseInt(catLinker) === parseInt(cat.linker));

		return cat || { name: catLinker };
	};

	return (
		<>
			<div>
				<p className="text-center h6 text-primary text-capitalize">
					Payroll Items For {params.staffName} {params.staffNo}
				</p>
				<div className="d-flex justify-content-around">
					<button
						className="btn btn-sm btn-outline-secondary"
						onClick={() => navigate(-1)}
					>
						<ArrowBarLeft></ArrowBarLeft> Go Back
					</button>
					{Items.length > 0 ? (
						<>
							<button
								className="btn btn-sm btn-primary"
								onClick={() => {
									setPayslipModal(true);
								}}
							>
								<BookmarkPlus /> Generate Payslip
							</button>

							{!ViewPaySlips ? (
								<button
									className="btn btn-sm btn-primary"
									onClick={() => {
										setViewPaySlips(true);
									}}
								>
									<Book /> View Payslips
								</button>
							) : (
								<button
									className="btn btn-sm btn-primary"
									onClick={() => {
										setViewPaySlips(false);
									}}
								>
									<CurrencyDollar /> View PayItems
								</button>
							)}
						</>
					) : null}
					<button
						className="btn btn-sm btn-primary"
						onClick={() => {
							setShowModal(true);
							setType("add");
							setItem({ catLinker: "", amount: "", details: "" });
						}}
					>
						<PlusCircle /> Add Items
					</button>
				</div>
				{ViewPaySlips ? (
					<PaySlips></PaySlips>
				) : (
					<>
						<table className="table table-sm" id="payroll-items">
							{Items.length > 0 ? (
								<tbody>
									{Items.map((item, index) => (
										<tr key={index} className="item">
											<td>
												{GetCat(item.catLinker).name}{" "}
												{item.live != 1 ? (
													<small
														className="text-success align-text-bottom text"
														style={{ fontSize: 8 }}
													>
														<br />
														<em>offline</em>
													</small>
												) : null}
											</td>
											<td>{item.details}</td>
											<td>{item.amount}</td>
											<td>
												<button
													className="btn btn-xs btn-outline-info mx-1"
													onClick={() => {
														setShowModal(true);
														setType("edit");
														setItem({ ...item });
													}}
												>
													{" "}
													<PencilSquare style={{ cursor: "pointer" }} />
												</button>
												<button
													className="btn btn-xs btn-outline-danger mx-1"
													onClick={() => {
														setShowModal(true);
														setType("delete");
														setItem({ ...item });
													}}
												>
													{" "}
													<Trash style={{ cursor: "pointer" }} />
												</button>
											</td>
										</tr>
									))}
								</tbody>
							) : (
								<NoPayrollItems></NoPayrollItems>
							)}
						</table>
						<GeneratePdf
							id={"payroll-items"}
							name={`${params.staffName} ${params.staffNo}-payroll-items`}
						></GeneratePdf>
					</>
				)}

				<PayrollItemCrud
					setShowModal={setShowModal}
					ShowModal={ShowModal}
					item={Item}
					setItem={setItem}
					type={Type}
					cats={Cats}
					staffName={params.staffName}
					staffNo={params.staffNo}
					staffLinker={params.staffLinker}
				></PayrollItemCrud>
			</div>
			<GeneratePayslip
				setShowModal={setPayslipModal}
				ShowModal={PayslipModal}
				items={Items}
				staffName={params.staffName}
				staffNo={params.staffNo}
				staffLinker={params.staffLinker}
			></GeneratePayslip>
		</>
	);
};

export default PayrollItems;
