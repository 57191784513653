import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RegisterLiveCred, updateLiveCred } from "../../Network/CredApi";

const StudentCrud = (props) => {
	const Structures = useSelector((state) => state.structure.structures);

	const Levels = Structures.filter(
		(structure) =>
			structure.type === "level" && parseInt(structure.deleted) === 0
	);

	const Periods = Structures.filter(
		(structure) =>
			structure.type === "period" && parseInt(structure.deleted) === 0
	);

	const Faculties = Structures.filter(
		(structure) =>
			structure.type === "faculty" && parseInt(structure.deleted) === 0
	);

	const Years = Structures.filter(
		(structure) =>
			structure.type === "academic-year" && parseInt(structure.deleted) === 0
	);
	//redux dispatch
	const dispatch = useDispatch();

	const online = useSelector((state) => state.online.online);
	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const [Loading, setLoading] = useState(false);

	//add guardian
	const AddGuardian = async (studentLinker, name, email, contact, linker) => {
		await RegisterLiveCred(
			{
				firstname: name,
				email,
				contact,
				refLinker: studentLinker,
				linker,
				instLinker: ActiveCred.linker,
				credLinker: ActiveCred.linker,
				live: 1,
				status: 0,
				trace: linker,
				deleted: 0,
				guardian: 1,
			},
			"guardian/add",
			User.token,
			dispatch
		);
	};

	//add student
	const AddStudent = async () => {
		let linker = Date.now();
		let {
			guardianOneName,
			guardianOneEmail,
			guardianOneContact,
			guardianTwoName,
			guardianTwoEmail,
			guardianTwoContact,
		} = props.student;

		AddGuardian(
			linker,
			guardianOneName,
			guardianOneEmail,
			guardianOneContact,
			linker + 1
		);
		AddGuardian(
			linker,
			guardianTwoName,
			guardianTwoEmail,
			guardianTwoContact,
			linker + 2
		);

		await RegisterLiveCred(
			{
				...props.student,
				linker,
				instLinker: ActiveCred.linker,
				credLinker: ActiveCred.linker,
				live: 1,
				status: 0,
				trace: linker,
				deleted: 0,
				student: 1,
			},
			"student/add",
			User.token,
			dispatch
		);
	};

	//edit student
	const EditStudent = async () => {
		let {
			linker,
			credLinker,
			trace,
			deleted,
			guardianOneName,
			guardianOneEmail,
			guardianOneContact,
			guardianOneLinker,
			guardianOneLive,
			guardianTwoName,
			guardianTwoEmail,
			guardianTwoContact,
			guardianTwoLinker,
			guardianTwoLive,
			guardianOneId,
			guardianTwoId,
		} = props.student;

		trace = Date.now();
		credLinker = ActiveCred.linker;
		deleted = props.type === "delete" ? 1 : deleted;

		EditGuardian(
			guardianOneName,
			guardianOneEmail,
			guardianOneContact,
			trace,
			deleted,
			guardianOneId
		);
		EditGuardian(
			guardianTwoName,
			guardianTwoEmail,
			guardianTwoContact,
			trace,
			deleted,
			guardianTwoId
		);

		await updateLiveCred(
			{ ...props.student, trace, credLinker, deleted },
			"student/edit",
			User.token,
			dispatch
		);
	};

	const EditGuardian = async (name, email, contact, trace, deleted, id) => {
		await updateLiveCred(
			{
				firstname: name,
				email,
				contact,
				trace,
				deleted,
				instLinker: ActiveCred.instLinker,
				id,
			},
			"guardian/edit",
			User.token,
			dispatch
		);
	};

	const HandleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (props.type === "add") {
			await AddStudent();
		} else {
			await EditStudent();
		}
		setLoading(false);
		props.setShowModal(false);
	};

	return (
		<div>
			<Modal
				show={props.ShowModal}
				onHide={() => props.setShowModal(false)}
				backdrop="static"
				centered
			>
				<Modal.Header closeButton>
					<strong className="text-capitalize text-center">
						{props.type} Student :{" "}
					</strong>
					<br />
					{props.type === "delete" ? null : (
						<small style={{ fontSize: "16" }}> Fill Whats Applicable</small>
					)}
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={HandleSubmit}>
						<div className="form-group">
							<div className="row">
								<div className="col-md-6">
									<label className="mb-2 text-capitalize"> Reg/Adm No:</label>
									<input
										className="rounded form-control"
										placeholder={`Enter Studentf Reg No`}
										value={props.student.studentReg}
										onChange={(e) =>
											props.setStudent({
												...props.student,
												studentReg: e.target.value,
											})
										}
										readOnly={props.type === "delete" ? true : false}
									/>
									<hr />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-capitalize">Surname:</label>
									<input
										className="rounded form-control"
										placeholder={`Enter Surname`}
										value={props.student.surname}
										onChange={(e) =>
											props.setStudent({
												...props.student,
												surname: e.target.value,
											})
										}
										readOnly={props.type === "delete" ? true : false}
									/>
									<hr />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-capitalize">FirstName:</label>
									<input
										className="rounded form-control"
										placeholder={`Enter firstname`}
										value={props.student.firstname}
										onChange={(e) =>
											props.setStudent({
												...props.student,
												firstname: e.target.value,
											})
										}
										readOnly={props.type === "delete" ? true : false}
									/>
									<hr />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-capitalize">LastName:</label>
									<input
										className="rounded form-control"
										placeholder={`Enter lastname`}
										value={props.student.lastname}
										onChange={(e) =>
											props.setStudent({
												...props.student,
												lastname: e.target.value,
											})
										}
										readOnly={props.type === "delete" ? true : false}
									/>
									<hr />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-capitalize">Email:</label>
									<input
										className="rounded form-control"
										placeholder={`Enter email`}
										value={props.student.email}
										onChange={(e) =>
											props.setStudent({
												...props.student,
												email: e.target.value.toLowerCase(),
											})
										}
										type="email"
										readOnly={props.type === "delete" ? true : false}
									/>
									<hr />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-capitalize">Contact:</label>
									<input
										className="rounded form-control"
										placeholder={`Enter contact`}
										value={props.student.contact}
										onChange={(e) =>
											props.setStudent({
												...props.student,
												contact: e.target.value,
											})
										}
										readOnly={props.type === "delete" ? true : false}
									/>
									<hr />
								</div>
								{Years.length > 0 ? (
									<div className="col-md-6">
										<label>Academic Year :</label>
										<select
											className="form-control rounded"
											onChange={(e) =>
												props.setStudent({
													...props.student,
													year: e.target.value,
												})
											}
											required
											value={props.student.year}
										>
											<option value={""}>Select AcYear</option>
											{Years.map((Year, index) => (
												<option key={index} value={Year.linker}>
													{Year.name}
												</option>
											))}
										</select>
										<hr />
									</div>
								) : null}
								{Faculties.length > 0 ? (
									<div className="col-md-6">
										<label>Faculty :</label>
										<select
											className="form-control rounded"
											onChange={(e) =>
												props.setStudent({
													...props.student,
													faculty: e.target.value,
												})
											}
											value={props.student.faculty}
											required
										>
											<option value={""}>Select Faculty</option>
											{Faculties.map((faculty, index) => (
												<option key={index} value={faculty.linker}>
													{faculty.name}
												</option>
											))}
										</select>
										<hr />
									</div>
								) : null}

								{Levels.length > 0 ? (
									<div className="col-md-6">
										<label>Level :</label>
										<select
											className="form-control rounded"
											onChange={(e) =>
												props.setStudent({
													...props.student,
													level: e.target.value,
												})
											}
											value={props.student.level}
											required
										>
											<option value={""}>Select Level</option>
											{Levels.map((level, index) => (
												<option key={index} value={level.linker}>
													{level.name}
												</option>
											))}
										</select>
										<hr />
									</div>
								) : null}
								{Periods.length > 0 ? (
									<div className="col-md-6">
										<label>Period :</label>
										<select
											className="form-control rounded"
											onChange={(e) =>
												props.setStudent({
													...props.student,
													period: e.target.value,
												})
											}
											value={props.student.period}
										>
											<option value={"all"}>Select Period</option>
											{Periods.map((period, index) => (
												<option key={index} value={period.linker}>
													{period.name}
												</option>
											))}
										</select>
										<hr />
									</div>
								) : null}
								<div className="col-md-6">
									<label className="mb-2 text-capitalize">
										1st Guardian Name:
									</label>
									<input
										className="rounded form-control"
										placeholder={`Enter 1st Guardian Name`}
										value={props.student.guardianOneName}
										onChange={(e) =>
											props.setStudent({
												...props.student,
												guardianOneName: e.target.value,
											})
										}
										readOnly={props.type === "delete" ? true : false}
									/>
									<hr />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-capitalize">
										1st Guardian Contact:
									</label>
									<input
										className="rounded form-control"
										placeholder={`Enter 1st Guardian Contact`}
										value={props.student.guardianOneContact}
										onChange={(e) =>
											props.setStudent({
												...props.student,
												guardianOneContact: e.target.value,
											})
										}
										readOnly={props.type === "delete" ? true : false}
									/>
									<hr />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-capitalize">
										1st Guardian Email:
									</label>
									<input
										className="rounded form-control"
										placeholder={`Enter 1st Guardian Email`}
										value={props.student.guardianOneEmail}
										onChange={(e) =>
											props.setStudent({
												...props.student,
												guardianOneEmail: e.target.value.toLowerCase(),
											})
										}
										type="email"
										readOnly={props.type === "delete" ? true : false}
									/>
									<hr />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-capitalize">
										2nd Guardian Name:
									</label>
									<input
										className="rounded form-control"
										placeholder={`Enter 2nd Guardian Name`}
										value={props.student.guardianTwoName}
										onChange={(e) =>
											props.setStudent({
												...props.student,
												guardianTwoName: e.target.value,
											})
										}
										readOnly={props.type === "delete" ? true : false}
									/>
									<hr />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-capitalize">
										2nd Guardian Contact:
									</label>
									<input
										className="rounded form-control"
										placeholder={`Enter 2nd Guardian Contact`}
										value={props.student.guardianTwoContact}
										onChange={(e) =>
											props.setStudent({
												...props.student,
												guardianTwoContact: e.target.value,
											})
										}
										readOnly={props.type === "delete" ? true : false}
									/>
									<hr />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-capitalize">
										2nd Guardian Email:
									</label>
									<input
										className="rounded form-control"
										placeholder={`Enter 2nd Guardian Email`}
										value={props.student.guardianTwoEmail}
										onChange={(e) =>
											props.setStudent({
												...props.student,
												guardianTwoEmail: e.target.value.toLowerCase(),
											})
										}
										type="email"
										readOnly={props.type === "delete" ? true : false}
									/>
									<hr />
								</div>
							</div>
						</div>

						<div className="d-flex justify-content-around mb-2">
							{Loading ? (
								<span className="spinner-border text-primary"></span>
							) : props.type === "delete" ? (
								<Button variant="danger" type="submit">
									Delete
								</Button>
							) : (
								<Button variant="primary" type="submit">
									Save
								</Button>
							)}
							<Button
								variant="secondary"
								type="button"
								onClick={() => props.setShowModal(false)}
							>
								Cancel
							</Button>
						</div>
					</Form>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default StudentCrud;
