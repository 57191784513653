import swal from "sweetalert";
import {
	addSubject,
	fetchSubjects,
	updateSubject,
} from "../reducers/SubjectSlice";
import { ApiUrl } from "./Urls";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchSubjects = async (
	instLinker,
	token,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB("subjects", (subject) => subject.instLinker == instLinker)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchSubjects(data));
				FetchLiveSubjects(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("subjects", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateSubject(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLiveSubjects = (
	token,
	instLinker,
	online,
	dispatch,
	subjects
) => {
	fetch(`${ApiUrl}/subject/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.subjects.map((onlineSubject) => {
					let localSubject = subjects.find(
						(item) => parseInt(item.linker) === parseInt(onlineSubject.linker)
					);
					if (!localSubject) {
						saveToIDB("subjects", onlineSubject.linker, onlineSubject);
						dispatch(updateSubject(onlineSubject));
					} else if (
						parseInt(localSubject.trace) > parseInt(onlineSubject.trace)
					) {
						updateLiveSubject(localSubject, token, dispatch);
					} else if (
						parseInt(localSubject.trace) < parseInt(onlineSubject.trace)
					) {
						saveToIDB("subjects", onlineSubject.linker, onlineSubject);
						dispatch(updateSubject(onlineSubject));
					}
				});

				subjects.map((subject) => {
					if (!subject.id) {
						addLiveSubject(subject, instLinker, token, dispatch);
					} else if (parseInt(subject.live) === 0) {
						updateLiveSubject(subject, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLiveSubject = (subject, instLinker, token, dispatch) => {
	dispatch(updateSubject({ ...subject, live: 0 }));
	saveToIDB("subjects", subject.linker, { ...subject, live: 0 });
	fetch(`${ApiUrl}/subject/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...subject,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateSubject(res.subject));
				saveToIDB("subjects", res.subject.linker, res.subject);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLiveSubject = (subject, token, dispatch) => {
	dispatch(updateSubject({ ...subject, live: 0 }));
	saveToIDB("subjects", subject.linker, { ...subject, live: 0 });
	fetch(`${ApiUrl}/subject/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...subject,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateSubject(res.subject));
				saveToIDB("subjects", res.subject.linker, res.subject);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};

/*
export const FetchSubjects = async (
	instLinker,
	token,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveSubjects(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updateSubject(payload));
	}
};

export const addLiveSubject = (subject, instLinker, token, dispatch) => {
	return fetch(`${ApiUrl}/subject/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...subject,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateSubject(res.subject));
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
				return 0;
			}
		})
		.catch((err) => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
			return { status: "error" };
		});
};

export const updateLiveSubject = (subject, token, dispatch) => {
	return fetch(`${ApiUrl}/subject/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...subject,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateSubject(res.subject));
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
			return { status: "error" };
		});
};

export const FetchLiveSubjects = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/subject/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchSubjects(res.subjects));
			}
		})
		.catch(() => {});
};*/
