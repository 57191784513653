import React, { useState, useEffect } from "react";
import LibraryCatCrud from "./LibraryCatCrud";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, Trash, PencilSquare } from "react-bootstrap-icons";
import NoLibraryCats from "./NoLibraryCats";
import { FetchLibraryCats } from "../../../Network/LibraryCatApi";
import GeneratePdf from "../../../Utils/GeneratePdf";

const LibraryCats = () => {
	useEffect(() => {
		FetchLibraryCats(User.token, ActiveCred.instLinker, dispatch, online);
	}, []);

	const [ShowModal, setShowModal] = useState(false);
	const [LibraryCat, setLibraryCat] = useState({ name: "" });
	const [Search, setSearch] = useState("");
	const [Type, setType] = useState("add");

	//redux dispatch
	const dispatch = useDispatch();

	const online = useSelector((state) => state.online.online);
	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const LibraryCats = useSelector((state) => state.libCat.libCats);

	const SearchLibraryCats = LibraryCats.filter(
		(mode) =>
			(mode.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
				mode.details.toLowerCase().search(Search.toLowerCase()) > -1 ||
				Search === "") &&
			parseInt(mode.deleted) === 0
	).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	return (
		<div>
			<p className="text-center h6 text-primary text-capitalize">
				Books Categories
			</p>
			<table className="table table-sm" id="libcats">
				<thead className="thead-dark">
					<tr>
						<th scope="column" colSpan={2}>
							<input
								type={"text"}
								placeholder={`Search Book Category`}
								value={Search}
								onChange={(e) => setSearch(e.target.value)}
								className="rounded form-control"
							/>
						</th>
						<td>
							<button
								className="btn btn-sm btn-primary"
								onClick={() => {
									setShowModal(true);
									setType("add");
									setLibraryCat({ name: "", details: "" });
								}}
							>
								<PlusCircle /> Book Category
							</button>
						</td>
					</tr>
				</thead>
				{SearchLibraryCats.length > 0 ? (
					<tbody>
						{SearchLibraryCats.map((cat, index) => (
							<tr key={index} className="item">
								<td>
									{cat.name}{" "}
									{cat.live != 1 ? (
										<small
											className="text-success align-text-bottom text"
											style={{ fontSize: 8 }}
										>
											<br />
											<em>offline</em>
										</small>
									) : null}
								</td>
								<td>{cat.details}</td>
								<td>
									<button
										className="btn btn-xs btn-outline-info mx-1"
										onClick={() => {
											setShowModal(true);
											setType("edit");
											setLibraryCat({ ...cat });
										}}
									>
										{" "}
										<PencilSquare style={{ cursor: "pointer" }} />
									</button>
									<button
										className="btn btn-xs btn-outline-danger mx-1"
										onClick={() => {
											setShowModal(true);
											setType("delete");
											setLibraryCat({ ...cat });
										}}
									>
										{" "}
										<Trash style={{ cursor: "pointer" }} />
									</button>
								</td>
							</tr>
						))}
					</tbody>
				) : (
					<NoLibraryCats></NoLibraryCats>
				)}
			</table>
			<GeneratePdf id={"libcats"} name={"library-categories"}></GeneratePdf>
			<LibraryCatCrud
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				libraryCat={LibraryCat}
				setLibraryCat={setLibraryCat}
				type={Type}
			></LibraryCatCrud>
		</div>
	);
};

export default LibraryCats;
