import swal from "sweetalert";
import { setActiveInst, updateInst } from "../reducers/InstSlice";
import { RegisterLiveCred } from "./CredApi";
import { ApiUrl } from "./Urls";

export const RegisterLiveInst = async (inst, User, dispatch) => {
	return await fetch(`${ApiUrl}/inst/register`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${User.token}`,
		},
		body: JSON.stringify({
			...inst,
			trace: inst.linker,
			live: 1,
		}),
	})
		.then((res) => res.json())
		.then(async (res) => {
			if (res.status === 201) {
				dispatch(updateInst({ ...res.inst, sub: inst.sub || 0 }));
				dispatch(setActiveInst({ ...res.inst, sub: inst.sub || 0 }));
				return await RegisterLiveCred(
					{
						name: User.name,
						linker: inst.linker,
						trace: inst.linker,
						status: 0,
						deleted: 0,
						instLinker: inst.linker,
					},
					"staff/super",
					User.token,
					dispatch
				);
			} else {
				return 0;
			}

			return res;
		})
		.catch((err) => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
			return { status: "error" };
		});
};

export const updateLiveInst = (inst, token, dispatch) => {
	fetch(`${ApiUrl}/inst/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...inst,
			instLinker: inst.linker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(setActiveInst({ ...res.inst, sub: inst.sub }));
			}
		})
		.catch((err) => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const fetchLiveInsts = (instLinkers, token) => {
	return fetch(`${ApiUrl}/auth/insts`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			instLinkers,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				return res;
			} else {
				return 0;
			}
		})
		.catch((err) => {
			return { status: "error" };
		});
};
