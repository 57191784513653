import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
const AddExamModal = ({
	exam,
	setShowModal,
	ShowModal,
	setExam,
	setViewType,
	Years,
	Faculties,
	Periods,
	Levels,
	Subjects,
	Activities,
}) => {
	return (
		<Modal
			show={ShowModal}
			onHide={() => {
				setShowModal(false);
				setExam({
					year: "",
					level: "",
					faculty: "",
					period: "",
					subject: "",
					activity: "",
					maxScore: "",
				});
			}}
			backdrop="static"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<p className="text-capitalize text-center">Add Scores</p>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form
					onSubmit={(e) => {
						e.preventDefault();
						setViewType("add");
						setShowModal(false);
					}}
				>
					<div>
						{Years.length > 0 ? (
							<div className="col-md-12">
								<label>Academic Year :</label>
								<select
									className="form-control rounded"
									onChange={(e) =>
										setExam({
											...exam,
											year: e.target.value,
										})
									}
									required
									value={exam.year}
								>
									<option value={""}>Select AcYear</option>
									{Years.map((Year, index) => (
										<option key={index} value={Year.linker}>
											{Year.name}
										</option>
									))}
								</select>
								<hr />
							</div>
						) : null}
						{Faculties.length > 0 ? (
							<div className="col-md-12">
								<label>Faculty :</label>
								<select
									className="form-control rounded"
									onChange={(e) =>
										setExam({
											...exam,
											faculty: e.target.value,
										})
									}
									value={exam.faculty}
									required
								>
									<option value={""}>Select Faculty</option>
									{Faculties.map((faculty, index) => (
										<option key={index} value={faculty.linker}>
											{faculty.name}
										</option>
									))}
								</select>
								<hr />
							</div>
						) : null}

						{Levels.length > 0 ? (
							<div className="col-md-12">
								<label>Level :</label>
								<select
									className="form-control rounded"
									onChange={(e) =>
										setExam({
											...exam,
											level: e.target.value,
										})
									}
									value={exam.level}
									required
								>
									<option value={""}>Select Level</option>
									{Levels.map((level, index) => (
										<option key={index} value={level.linker}>
											{level.name}
										</option>
									))}
								</select>
								<hr />
							</div>
						) : null}
						{Periods.length > 0 ? (
							<div className="col-md-12">
								<label>Period :</label>
								<select
									className="form-control rounded"
									onChange={(e) =>
										setExam({
											...exam,
											period: e.target.value,
										})
									}
									value={exam.period}
									required
								>
									<option value={""}>Select Period</option>
									{Periods.map((period, index) => (
										<option key={index} value={period.linker}>
											{period.name}
										</option>
									))}
								</select>
								<hr />
							</div>
						) : null}
						<div className="col-md-12">
							<label>
								Subject/Unit :{" "}
								{Subjects.length < 1 ? (
									<small>
										<em className="text-success">
											Go To Menu/Dashboard to add subjects/units
										</em>
									</small>
								) : null}
							</label>
							<select
								className="form-control rounded"
								onChange={(e) =>
									setExam({
										...exam,
										subject: e.target.value,
									})
								}
								value={exam.subject}
								required
							>
								<option value={""}>Select Subject/Unit</option>
								{Subjects.map((subject, index) => (
									<option key={index} value={subject.linker}>
										{subject.name}
									</option>
								))}
							</select>
							<hr />
						</div>
						<div className="col-md-12">
							<label>
								Assessment :
								{Activities.length < 1 ? (
									<small>
										<em className="text-success">
											Go To Menu/Dashboard to add Assessments
										</em>
									</small>
								) : null}
							</label>
							<select
								className="form-control rounded"
								onChange={(e) =>
									setExam({
										...exam,
										activity: e.target.value,
									})
								}
								value={exam.activity}
							>
								<option value={""}>Select Subject/Unit Assessment</option>
								{Activities.map((activity, index) => (
									<option key={index} value={activity.linker}>
										{activity.name}
									</option>
								))}
							</select>
							<hr />
						</div>
						<div className="col-md-12">
							<label>MaxScore/OutOff :</label>
							<input
								className="form-control rounded"
								onChange={(e) =>
									setExam({
										...exam,
										maxScore: e.target.value,
									})
								}
								value={exam.maxScore}
								required
								type="number"
								placeholder="Enter maxscore/outoff"
							/>

							<hr />
						</div>
					</div>

					<div className="d-flex justify-content-around mb-2">
						<Button variant="primary" type="submit">
							Okay
						</Button>

						<Button
							variant="secondary"
							type="button"
							onClick={() => {
								setShowModal(false);
								setExam({
									year: "",
									level: "",
									faculty: "",
									period: "",
									subject: "",
									activity: "",
									maxScore: "",
								});
							}}
						>
							Cancel
						</Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default AddExamModal;
