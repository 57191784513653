import swal from "sweetalert";
import { addLibCat, fetchLibCats, updateLibCat } from "../reducers/LibCatSlice";
import { ApiUrl } from "./Urls";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchLibraryCats = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB(
		"librarycats",
		(libraryCat) => libraryCat.instLinker == instLinker
	)
		.then((data) => {
			console.log(data);
			if (from === "fetch") {
				dispatch(fetchLibCats(data));
				FetchLiveLibraryCats(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("librarycats", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateLibCat(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLiveLibraryCats = (
	token,
	instLinker,
	online,
	dispatch,
	libraryCats
) => {
	fetch(`${ApiUrl}/library-categories/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.libraryCats.map((onlineLibraryCat) => {
					let localLibraryCat = libraryCats.find(
						(item) =>
							parseInt(item.linker) === parseInt(onlineLibraryCat.linker)
					);
					if (!localLibraryCat) {
						saveToIDB("librarycats", onlineLibraryCat.linker, onlineLibraryCat);
						dispatch(updateLibCat(onlineLibraryCat));
					} else if (
						parseInt(localLibraryCat.trace) > parseInt(onlineLibraryCat.trace)
					) {
						updateLiveLibraryCat(localLibraryCat, token, dispatch);
					} else if (
						parseInt(localLibraryCat.trace) < parseInt(onlineLibraryCat.trace)
					) {
						saveToIDB("librarycats", onlineLibraryCat.linker, onlineLibraryCat);
						dispatch(updateLibCat(onlineLibraryCat));
					}
				});

				libraryCats.map((libraryCat) => {
					if (!libraryCat.id) {
						addLiveLibraryCat(libraryCat, instLinker, token, dispatch);
					} else if (parseInt(libraryCat.live) === 0) {
						updateLiveLibraryCat(libraryCat, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLiveLibraryCat = (libraryCat, instLinker, token, dispatch) => {
	dispatch(updateLibCat({ ...libraryCat, live: 0 }));
	saveToIDB("librarycats", libraryCat.linker, { ...libraryCat, live: 0 });
	fetch(`${ApiUrl}/library-categories/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...libraryCat,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateLibCat(res.libraryCat));
				saveToIDB("librarycats", res.libraryCat.linker, res.libraryCat);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLiveLibraryCat = (libraryCat, token, dispatch) => {
	dispatch(updateLibCat({ ...libraryCat, live: 0 }));
	saveToIDB("librarycats", libraryCat.linker, { ...libraryCat, live: 0 });
	fetch(`${ApiUrl}/library-categories/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...libraryCat,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateLibCat(res.libraryCat));
				saveToIDB("librarycats", res.libraryCat.linker, res.libraryCat);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};

/*
export const FetchLibraryCats = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveLibraryCats(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updateLibCat(payload));
	}
};

export const addLiveLibraryCat = (libraryCat, instLinker, token, dispatch) => {
	return fetch(`${ApiUrl}/library-categories/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...libraryCat,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateLibCat(res.libraryCat));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLiveLibraryCat = (libraryCat, token, dispatch) => {
	return fetch(`${ApiUrl}/library-categories/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...libraryCat,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateLibCat(res.libraryCat));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLiveLibraryCats = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/library-categories/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchLibCats(res.libraryCats));
			}
		})
		.catch(() => {});
};*/
