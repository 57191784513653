import swal from "sweetalert";
import { addLesson, fetchLessons, updateLesson } from "../reducers/LessonSlice";
import { ApiUrl } from "./Urls";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchLessons = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB("lessons", (lesson) => lesson.instLinker == instLinker)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchLessons(data));
				FetchLiveLessons(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("lessons", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateLesson(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLiveLessons = (
	token,
	instLinker,
	online,
	dispatch,
	lessons
) => {
	fetch(`${ApiUrl}/lesson/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.lessons.map((onlineLesson) => {
					let localLesson = lessons.find(
						(item) => parseInt(item.linker) === parseInt(onlineLesson.linker)
					);
					if (!localLesson) {
						saveToIDB("lessons", onlineLesson.linker, onlineLesson);
						dispatch(updateLesson(onlineLesson));
					} else if (
						parseInt(localLesson.trace) > parseInt(onlineLesson.trace)
					) {
						updateLiveLesson(localLesson, token, dispatch);
					} else if (
						parseInt(localLesson.trace) < parseInt(onlineLesson.trace)
					) {
						saveToIDB("lessons", onlineLesson.linker, onlineLesson);
						dispatch(updateLesson(onlineLesson));
					}
				});

				lessons.map((lesson) => {
					if (!lesson.id) {
						addLiveLesson(lesson, instLinker, token, dispatch);
					} else if (parseInt(lesson.live) === 0) {
						updateLiveLesson(lesson, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLiveLesson = (lesson, instLinker, token, dispatch) => {
	dispatch(updateLesson({ ...lesson, live: 0 }));
	saveToIDB("lessons", lesson.linker, { ...lesson, live: 0 });
	fetch(`${ApiUrl}/lesson/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...lesson,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateLesson(res.lesson));
				saveToIDB("lessons", res.lesson.linker, res.lesson);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLiveLesson = (lesson, token, dispatch) => {
	dispatch(updateLesson({ ...lesson, live: 0 }));
	saveToIDB("lessons", lesson.linker, { ...lesson, live: 0 });
	fetch(`${ApiUrl}/lesson/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...lesson,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateLesson(res.lesson));
				saveToIDB("lessons", res.lesson.linker, res.lesson);
			} else {
			}
		})
		.catch((err) => {});
};
/*
export const FetchLessons = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveLessons(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updateLesson(payload));
	}
};

export const addLiveLesson = (lesson, instLinker, token, dispatch) => {
	return fetch(`${ApiUrl}/lesson/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...lesson,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateLesson(res.lesson));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLiveLesson = (lesson, token, dispatch) => {
	return fetch(`${ApiUrl}/lesson/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...lesson,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateLesson(res.lesson));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLiveLessons = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/lesson/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchLessons(res.lessons));
			}
		})
		.catch(() => {});
};*/
