import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addLiveExam, updateLiveExam } from "../../../Network/ExamApi";

const ExamCrud = ({ type, ExamCrudModal, setExamCrudModal, Exam, setExam }) => {
	//redux dispatch
	const dispatch = useDispatch();

	const online = useSelector((state) => state.online.online);
	const User = useSelector((state) => state.auth.user);
	const Structures = useSelector((state) => state.structure.structures);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const [Loading, setLoading] = useState(false);

	const Levels = Structures.filter(
		(structure) =>
			structure.type === "level" && parseInt(structure.deleted) === 0
	);

	const Periods = Structures.filter(
		(structure) =>
			structure.type === "period" && parseInt(structure.deleted) === 0
	);

	const Faculties = Structures.filter(
		(structure) =>
			structure.type === "faculty" && parseInt(structure.deleted) === 0
	);

	const Years = Structures.filter(
		(structure) =>
			structure.type === "academic-year" && parseInt(structure.deleted) === 0
	);

	const Subjects = useSelector((state) => state.subject.subjects)
		.filter(
			(obj, index, self) =>
				self.findIndex((o) => o.linker == obj.linker) === index
		)
		.filter(
			(subject) =>
				(parseInt(subject.levelLinker) == Exam.levelLinker ||
					Exam.levelLinker == "") &&
				(parseInt(subject.periodLinker) == Exam.periodLinker ||
					Exam.periodLinker == "") &&
				(parseInt(subject.facultyLinker) == Exam.facultyLinker ||
					Exam.facultyLinker == "") &&
				parseInt(subject.deleted) === 0
		);

	const Activities = useSelector((state) => state.activity.activities)
		.filter(
			(obj, index, self) =>
				self.findIndex((o) => o.linker == obj.linker) === index
		)
		.filter(
			(activity) =>
				(parseInt(activity.levelLinker) == Exam.levelLinker ||
					Exam.levelLinker == "") &&
				(parseInt(activity.periodLinker) == Exam.periodLinker ||
					Exam.periodLinker == "") &&
				(parseInt(activity.facultyLinker) == Exam.facultyLinker ||
					Exam.facultyLinker == "") &&
				(parseInt(activity.subjectLinker) == Exam.subjectLinker ||
					Exam.subjectLinker == "") &&
				parseInt(activity.deleted) === 0
		);

	const EditExam = async () => {
		let { student, trace, deleted } = Exam;
		trace = Date.now();
		deleted = type === "delete" ? 1 : deleted;

		await updateLiveExam(
			{
				...Exam,
				trace,
				credLinker: ActiveCred.linker,
				instLinker: ActiveCred.instLinker,
				deleted,
				studentLinker: student.linker,
			},
			User.token,
			dispatch
		);
	};

	const AddExam = async () => {
		let {
			linker,

			student,
		} = Exam;

		await addLiveExam(
			{
				...Exam,
				instLinker: ActiveCred.linker,
				credLinker: ActiveCred.linker,
				studentLinker: student.linker,
				live: 1,
				status: 0,
				trace: linker,
				deleted: 0,
			},
			ActiveCred.instLinker,
			User.token,
			dispatch
		);
	};

	const HandleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (type === "add") {
			await AddExam();
		} else {
			await EditExam();
		}
		setLoading(false);
		setExamCrudModal(false);
	};

	return (
		<Modal
			show={ExamCrudModal}
			onHide={() => setExamCrudModal(false)}
			backdrop="static"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<p className="text-capitalize text-center">{type} Score</p>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={HandleSubmit}>
					{Years.length > 0 ? (
						<div className="col-md-12">
							<label>Academic Year :</label>
							<select
								className="form-control rounded"
								readOnly={type === "delete" ? true : false}
								onChange={(e) =>
									setExam({
										...Exam,
										yearLinker: e.target.value,
									})
								}
								required
								value={Exam.yearLinker}
							>
								<option value={""}>Select AcYear</option>
								{Years.map((Year, index) => (
									<option key={index} value={Year.linker}>
										{Year.name}
									</option>
								))}
							</select>
							<hr />
						</div>
					) : null}
					{Faculties.length > 0 ? (
						<div className="col-md-12">
							<label>Faculty :</label>
							<select
								className="form-control rounded"
								readOnly={type === "delete" ? true : false}
								onChange={(e) =>
									setExam({
										...Exam,
										facultyLinker: e.target.value,
									})
								}
								value={Exam.facultyLinker}
								required
							>
								<option value={""}>Select Faculty</option>
								{Faculties.map((faculty, index) => (
									<option key={index} value={faculty.linker}>
										{faculty.name}
									</option>
								))}
							</select>
							<hr />
						</div>
					) : null}

					{Levels.length > 0 ? (
						<div className="col-md-12">
							<label>Level :</label>
							<select
								className="form-control rounded"
								readOnly={type === "delete" ? true : false}
								onChange={(e) =>
									setExam({
										...Exam,
										levelLinker: e.target.value,
									})
								}
								value={Exam.levelLinker}
								required
							>
								<option value={""}>Select Level</option>
								{Levels.map((level, index) => (
									<option key={index} value={level.linker}>
										{level.name}
									</option>
								))}
							</select>
							<hr />
						</div>
					) : null}
					{Periods.length > 0 ? (
						<div className="col-md-12">
							<label>Period :</label>
							<select
								className="form-control rounded"
								readOnly={type === "delete" ? true : false}
								onChange={(e) =>
									setExam({
										...Exam,
										periodLinker: e.target.value,
									})
								}
								value={Exam.periodLinker}
								required
							>
								<option value={""}>Select Period</option>
								{Periods.map((period, index) => (
									<option key={index} value={period.linker}>
										{period.name}
									</option>
								))}
							</select>
							<hr />
						</div>
					) : null}
					<div className="col-md-12">
						<label>
							Subject/Unit :{" "}
							{Subjects.length < 1 ? (
								<small>
									<em className="text-success">
										Go To Menu/Dashboard to add subjects/units
									</em>
								</small>
							) : null}
						</label>
						<select
							className="form-control rounded"
							readOnly={type === "delete" ? true : false}
							onChange={(e) =>
								setExam({
									...Exam,
									subjectLinker: e.target.value,
								})
							}
							value={Exam.subjectLinker}
							required
						>
							<option value={""}>Select Subject/Unit</option>
							{Subjects.map((subject, index) => (
								<option key={index} value={subject.linker}>
									{subject.name}
								</option>
							))}
						</select>
						<hr />
					</div>
					<div className="col-md-12">
						<label>
							Assessment :{" "}
							{Activities.length < 1 ? (
								<small>
									<em className="text-success">
										Go To Menu/Dashboard to add Assessments
									</em>
								</small>
							) : null}
						</label>
						<select
							className="form-control rounded"
							readOnly={type === "delete" ? true : false}
							onChange={(e) =>
								setExam({
									...Exam,
									activityLinker: e.target.value,
								})
							}
							value={Exam.activityLinker}
						>
							<option value={""}>Select Subject/Unit Assessment</option>
							{Activities.map((activity, index) => (
								<option key={index} value={activity.linker}>
									{activity.name}
								</option>
							))}
						</select>
						<hr />
					</div>
					<div className="col-md-12">
						<label>MaxScore/OutOff :</label>
						<input
							className="form-control rounded"
							readOnly={type === "delete" ? true : false}
							onChange={(e) =>
								setExam({
									...Exam,
									maxScore: e.target.value,
								})
							}
							value={Exam.maxScore}
							required
							type="number"
							placeholder="Enter maxscore/outoff"
						/>

						<hr />
					</div>
					<div className="col-md-12">
						<label>Score :</label>
						<input
							className="form-control rounded"
							readOnly={type === "delete" ? true : false}
							onChange={(e) =>
								setExam({
									...Exam,
									score: e.target.value,
								})
							}
							value={Exam.score}
							type="number"
							placeholder="Enter student score"
						/>

						<hr />
					</div>
					<div className="col-md-12">
						<label>Grade :</label>
						<input
							className="form-control rounded"
							readOnly={type === "delete" ? true : false}
							onChange={(e) =>
								setExam({
									...Exam,
									grade: e.target.value,
								})
							}
							value={Exam.grade}
							placeholder="Enter Score grade"
						/>

						<hr />
					</div>
					<div className="col-md-12">
						<label>Comment :</label>
						<input
							className="form-control rounded"
							readOnly={type === "delete" ? true : false}
							onChange={(e) =>
								setExam({
									...Exam,
									comment: e.target.value,
								})
							}
							value={Exam.comment}
							placeholder="Enter Score comment"
						/>

						<hr />
					</div>
					<div className="d-flex justify-content-around mb-2">
						{Loading ? (
							<span className="spinner-border text-primary"></span>
						) : type === "delete" ? (
							<Button variant="danger" type="submit">
								Delete
							</Button>
						) : (
							<Button variant="primary" type="submit">
								Save
							</Button>
						)}
						<Button
							variant="secondary"
							type="button"
							onClick={() => setExamCrudModal(false)}
						>
							Cancel
						</Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default ExamCrud;
