import swal from "sweetalert";
import { addExam, fetchExams, updateExam } from "../reducers/ExamSlice";
import { ApiUrl } from "./Urls";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchExams = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB("exams", (exam) => exam.instLinker == instLinker)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchExams(data));
				FetchLiveExams(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("exams", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateExam(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLiveExams = (token, instLinker, online, dispatch, exams) => {
	fetch(`${ApiUrl}/exam/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.exams.map((onlineExam) => {
					let localExam = exams.find(
						(item) => parseInt(item.linker) === parseInt(onlineExam.linker)
					);
					if (!localExam) {
						saveToIDB("exams", onlineExam.linker, onlineExam);
						dispatch(updateExam(onlineExam));
					} else if (parseInt(localExam.trace) > parseInt(onlineExam.trace)) {
						updateLiveExam(localExam, token, dispatch);
					} else if (parseInt(localExam.trace) < parseInt(onlineExam.trace)) {
						saveToIDB("exams", onlineExam.linker, onlineExam);
						dispatch(updateExam(onlineExam));
					}
				});

				exams.map((exam) => {
					if (!exam.id) {
						addLiveExam(exam, instLinker, token, dispatch);
					} else if (parseInt(exam.live) === 0) {
						updateLiveExam(exam, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLiveExam = (exam, instLinker, token, dispatch) => {
	dispatch(updateExam({ ...exam, live: 0 }));
	saveToIDB("exams", exam.linker, { ...exam, live: 0 });
	fetch(`${ApiUrl}/exam/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...exam,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateExam(res.exam));
				saveToIDB("exams", res.exam.linker, res.exam);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLiveExam = (exam, token, dispatch) => {
	dispatch(updateExam({ ...exam, live: 0 }));
	saveToIDB("exams", exam.linker, { ...exam, live: 0 });
	fetch(`${ApiUrl}/exam/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...exam,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateExam(res.exam));
				saveToIDB("exams", res.exam.linker, res.exam);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};

/*
export const FetchExams = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveExams(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updateExam(payload));
	}
};

export const addLiveExam = (exam, instLinker, token, dispatch) => {
	return fetch(`${ApiUrl}/exam/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...exam,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateExam(res.exam));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLiveExam = async (exam, token, dispatch) => {
	return await fetch(`${ApiUrl}/exam/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...exam,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateExam(res.exam));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLiveExams = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/exam/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchExams(res.exams));
			}
		})
		.catch(() => {});
};
*/
