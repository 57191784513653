import swal from "sweetalert";
import {
	addStoreCat,
	fetchStoreCats,
	updateStoreCat,
} from "../reducers/StoreCatSlice";
import { ApiUrl } from "./Urls";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchStoreCats = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB(
		"storecats",
		(storeCat) => storeCat.instLinker == instLinker
	)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchStoreCats(data));
				FetchLiveStoreCats(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("storecats", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateStoreCat(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLiveStoreCats = (
	token,
	instLinker,
	online,
	dispatch,
	storeCats
) => {
	fetch(`${ApiUrl}/store-categories/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.storeCats.map((onlineStoreCat) => {
					let localStoreCat = storeCats.find(
						(item) => parseInt(item.linker) === parseInt(onlineStoreCat.linker)
					);
					if (!localStoreCat) {
						saveToIDB("storecats", onlineStoreCat.linker, onlineStoreCat);
						dispatch(updateStoreCat(onlineStoreCat));
					} else if (
						parseInt(localStoreCat.trace) > parseInt(onlineStoreCat.trace)
					) {
						updateLiveStoreCat(localStoreCat, token, dispatch);
					} else if (
						parseInt(localStoreCat.trace) < parseInt(onlineStoreCat.trace)
					) {
						saveToIDB("storecats", onlineStoreCat.linker, onlineStoreCat);
						dispatch(updateStoreCat(onlineStoreCat));
					}
				});

				storeCats.map((storeCat) => {
					if (!storeCat.id) {
						addLiveStoreCat(storeCat, instLinker, token, dispatch);
					} else if (parseInt(storeCat.live) === 0) {
						updateLiveStoreCat(storeCat, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLiveStoreCat = (storeCat, instLinker, token, dispatch) => {
	dispatch(updateStoreCat({ ...storeCat, live: 0 }));
	saveToIDB("storecats", storeCat.linker, { ...storeCat, live: 0 });
	fetch(`${ApiUrl}/store-categories/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...storeCat,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateStoreCat(res.storeCat));
				saveToIDB("storecats", res.storeCat.linker, res.storeCat);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLiveStoreCat = (storeCat, token, dispatch) => {
	dispatch(updateStoreCat({ ...storeCat, live: 0 }));
	saveToIDB("storecats", storeCat.linker, { ...storeCat, live: 0 });
	fetch(`${ApiUrl}/store-categories/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...storeCat,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateStoreCat(res.storeCat));
				saveToIDB("storecats", res.storeCat.linker, res.storeCat);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};

/*export const FetchStoreCats = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveStoreCats(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updateStoreCat(payload));
	}
};

export const addLiveStoreCat = (storeCat, instLinker, token, dispatch) => {
	return fetch(`${ApiUrl}/store-categories/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...storeCat,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateStoreCat(res.storeCat));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLiveStoreCat = (storeCat, token, dispatch) => {
	return fetch(`${ApiUrl}/store-categories/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...storeCat,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateStoreCat(res.storeCat));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLiveStoreCats = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/store-categories/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchStoreCats(res.storeCats));
			}
		})
		.catch(() => {});
};*/
