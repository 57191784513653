import React from "react";
import { Trash, PencilSquare, PlusCircle } from "react-bootstrap-icons";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";

const Lesson = ({
	intervalLinker,
	Day,
	setType,
	setShowModal,
	setActiveLesson,
	level,
	faculty,
	period,
	Lessons,
}) => {
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Subjects = useSelector((state) => state.subject.subjects);

	const LessonData =
		Lessons.find(
			(lesson) =>
				parseInt(lesson.deleted) == 0 &&
				lesson.day == Day &&
				parseInt(lesson.intervalLinker) == parseInt(intervalLinker)
		) || {};

	const Subject = Subjects.find(
		(subject) =>
			parseInt(subject.deleted) == 0 &&
			parseInt(subject.linker) == parseInt(LessonData.subjectLinker)
	) || { name: "Unknown" };

	return LessonData &&
		(level?.linker
			? parseInt(Subject.levelLinker) == parseInt(level.linker)
			: true) &&
		(period?.linker
			? parseInt(Subject.periodLinker) == parseInt(period.linker)
			: true) &&
		(faculty?.linker
			? parseInt(Subject.facultyLinker) == parseInt(faculty.linker)
			: true) ? (
		<>
			<Dropdown className="m-0 p-0">
				<Dropdown.Toggle
					variant="transparent"
					className="m-0 p-0 text-start text-wrap"
				>
					<small>
						{Subject.name}{" "}
						{LessonData?.live != 1 ? (
							<small
								className="text-success align-text-bottom text"
								style={{ fontSize: 8 }}
							>
								<br />
								<em>offline</em>
							</small>
						) : null}
					</small>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item>
						<p>
							By : <em>{LessonData.tutor}</em>
						</p>
					</Dropdown.Item>

					<Dropdown.Item>
						<p>
							At : <em>{LessonData.venue}</em>
						</p>
					</Dropdown.Item>
					{parseInt(ActiveCred.admin) == 1 ? (
						<Dropdown.Item>
							<div className="d-flex justify-content-around">
								{" "}
								<PencilSquare
									onClick={() => {
										setShowModal(true);
										setType("edit");
										setActiveLesson({
											...LessonData,
											level,
											faculty,
											period,
										});
									}}
									className="text-info mx-1"
									style={{ cursor: "pointer" }}
								/>
								<Trash
									onClick={() => {
										setShowModal(true);
										setType("delete");
										setActiveLesson({
											...LessonData,
											level,
											faculty,
											period,
										});
									}}
									className="text-danger mx-1"
									style={{ cursor: "pointer" }}
								/>
							</div>
						</Dropdown.Item>
					) : null}
				</Dropdown.Menu>
			</Dropdown>
		</>
	) : parseInt(ActiveCred.admin) == 1 ? (
		<Dropdown.Item>
			<div className="d-flex justify-content-around">
				<PlusCircle
					onClick={() => {
						setType("add");
						setShowModal(true);
						setActiveLesson({
							level,
							faculty,
							period,
							deleted: 0,
							linker: Date.now(),
							tutor: "",
							venue: "",
							intervalLinker,
							subjectLinker: "",
							day: Day,
						});
					}}
					className="btn-link h6"
					style={{ cursor: "pointer" }}
				/>
			</div>
		</Dropdown.Item>
	) : null;
};

export default Lesson;
