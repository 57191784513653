import React, { useState, useEffect } from "react";
import StructureCrud from "./StructureCrud";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PlusCircle, Trash, PencilSquare } from "react-bootstrap-icons";
import NoStructure from "./NoStructure";
import { FetchLocalStuctures } from "../../../Network/StructureApi";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../../Utils/GeneratePdf";

const Structures = () => {
	const params = useParams();

	//redux dispatch
	const dispatch = useDispatch();

	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Structures = useSelector((state) => state.structure.structures);
	const online = useSelector((state) => state.online.online);
	const User = useSelector((state) => state.auth.user);

	useEffect(() => {
		FetchLocalStuctures(ActiveCred.instLinker, User.token, dispatch, online);
	}, [params.structureType]);

	const [ShowModal, setShowModal] = useState(false);
	const [Structure, setStructure] = useState({ name: "" });
	const [Search, setSearch] = useState("");
	const [Type, setType] = useState("add");

	const SearchStructure = Structures.filter(
		(structure) =>
			(structure.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
				Search === "") &&
			structure.type === params.structureType &&
			parseInt(structure.deleted) === 0
	).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	return (
		<div>
			<p className="text-center h6 text-primary text-capitalize">
				{SearchStructure.length} {`${params.structureType}s`}
			</p>
			<table className="table table-sm" id="structures">
				<thead className="thead-dark">
					<tr>
						<th scope="column">
							<input
								type={"text"}
								placeholder={`Search ${params.structureType}`}
								value={Search}
								onChange={(e) => setSearch(e.target.value)}
								className="rounded form-control"
							/>
						</th>
						<td>
							<button
								className="btn btn-sm btn-primary"
								onClick={() => {
									setShowModal(true);
									setType("add");
									setStructure({ name: "" });
								}}
							>
								<PlusCircle /> Add {params.structureType}
							</button>
						</td>
					</tr>
				</thead>

				{SearchStructure.length > 0 ? (
					<tbody>
						<PlainList
							list={SearchStructure}
							renderOnScroll
							renderItem={(structure) => (
								<tr key={structure.linker} className="item">
									<td>
										{structure.name}{" "}
										{structure.live != 1 ? (
											<small
												className="text-success align-text-bottom text"
												style={{ fontSize: 8 }}
											>
												<br />
												<em>offline</em>
											</small>
										) : null}
									</td>
									<td>
										<button
											className="btn btn-xs btn-outline-info mx-1"
											onClick={() => {
												setShowModal(true);
												setType("edit");
												setStructure({ ...structure });
											}}
										>
											{" "}
											<PencilSquare style={{ cursor: "pointer" }} />
										</button>
										<button
											className="btn btn-xs btn-outline-danger mx-1"
											onClick={() => {
												setShowModal(true);
												setType("delete");
												setStructure({ ...structure });
											}}
										>
											{" "}
											<Trash style={{ cursor: "pointer" }} />
										</button>
									</td>
								</tr>
							)}
						/>
					</tbody>
				) : (
					<NoStructure></NoStructure>
				)}
			</table>
			<GeneratePdf
				name={`${params.structureType}s`}
				id={"structures"}
			></GeneratePdf>
			<StructureCrud
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				structure={Structure}
				setStructure={setStructure}
				type={Type}
			></StructureCrud>
		</div>
	);
};

export default Structures;
