import swal from "sweetalert";
import {
	addNotification,
	fetchNotifications,
	updateNotification,
} from "../reducers/NotificationSlice";
import { ApiUrl } from "./Urls";

export const FetchNotifications = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveNotifications(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updateNotification(payload));
	}
};

export const addLiveNotification = (
	notification,
	instLinker,
	token,
	dispatch
) => {
	return fetch(`${ApiUrl}/notification/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...notification,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateNotification(res.notification));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLiveNotification = (notification, token, dispatch) => {
	return fetch(`${ApiUrl}/notification/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...notification,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateNotification(res.notification));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLiveNotifications = (token, instLinker, online, dispatch) => {
	return fetch(`${ApiUrl}/notification/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchNotifications(res.notifications));
			}
		})
		.catch(() => {});
};
