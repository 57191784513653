import swal from "sweetalert";
import {
	addPayment,
	fetchPayments,
	updatePayment,
} from "../reducers/PaymentSlice";
import { ApiUrl } from "./Urls";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchPayments = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB("payments", (payment) => payment.instLinker == instLinker)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchPayments(data));
				FetchLivePayments(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("payments", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updatePayment(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLivePayments = (
	token,
	instLinker,
	online,
	dispatch,
	payments
) => {
	fetch(`${ApiUrl}/payment/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.payments.map((onlinePayment) => {
					let localPayment = payments.find(
						(item) => parseInt(item.linker) === parseInt(onlinePayment.linker)
					);
					if (!localPayment) {
						saveToIDB("payments", onlinePayment.linker, onlinePayment);
						dispatch(updatePayment(onlinePayment));
					} else if (
						parseInt(localPayment.trace) > parseInt(onlinePayment.trace)
					) {
						updateLivePayment(localPayment, token, dispatch);
					} else if (
						parseInt(localPayment.trace) < parseInt(onlinePayment.trace)
					) {
						saveToIDB("payments", onlinePayment.linker, onlinePayment);
						dispatch(updatePayment(onlinePayment));
					}
				});

				payments.map((payment) => {
					if (!payment.id) {
						addLivePayment(payment, instLinker, token, dispatch);
					} else if (parseInt(payment.live) === 0) {
						updateLivePayment(payment, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLivePayment = (payment, instLinker, token, dispatch) => {
	dispatch(updatePayment({ ...payment, live: 0 }));
	saveToIDB("payments", payment.linker, { ...payment, live: 0 });
	fetch(`${ApiUrl}/payment/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...payment,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updatePayment(res.payment));
				saveToIDB("payments", res.payment.linker, res.payment);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLivePayment = (payment, token, dispatch) => {
	dispatch(updatePayment({ ...payment, live: 0 }));
	saveToIDB("payments", payment.linker, { ...payment, live: 0 });
	fetch(`${ApiUrl}/payment/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...payment,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updatePayment(res.payment));
				saveToIDB("payments", res.payment.linker, res.payment);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};
/*export const FetchPayments = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLivePayments(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updatePayment(payload));
	}
};

export const addLivePayment = (payment, instLinker, token, dispatch) => {
	return fetch(`${ApiUrl}/payment/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...payment,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updatePayment(res.payment));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLivePayment = (payment, token, dispatch) => {
	return fetch(`${ApiUrl}/payment/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...payment,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updatePayment(res.payment));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLivePayments = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/payment/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchPayments(res.payments));
			}
		})
		.catch(() => {});
};*/
