import React, { useState, useEffect } from "react";
import StoreCatCrud from "./StoreCatCrud";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, Trash, PencilSquare } from "react-bootstrap-icons";
import NoStoreCat from "./NoStoreCat";
import { FetchStoreCats } from "../../../Network/StoreCatApi";
import GeneratePdf from "../../../Utils/GeneratePdf";

const StoreCats = () => {
	useEffect(() => {
		FetchStoreCats(User.token, ActiveCred.instLinker, dispatch, online);
	}, []);

	const [ShowModal, setShowModal] = useState(false);
	const [StoreCat, setStoreCat] = useState({ name: "" });
	const [Search, setSearch] = useState("");
	const [Type, setType] = useState("add");

	//redux dispatch
	const dispatch = useDispatch();

	const online = useSelector((state) => state.online.online);
	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const StoreCats = useSelector((state) => state.storeCat.storeCats);

	const SearchStoreCat = StoreCats.filter(
		(storeCat) =>
			(storeCat.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
				Search === "") &&
			parseInt(storeCat.deleted) === 0
	).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	return (
		<div>
			<p className="text-center h6 text-primary text-capitalize">
				Store Categories
			</p>
			<table className="table table-sm" id="storecats">
				<thead className="thead-dark">
					<tr>
						<th scope="column" colSpan={2}>
							<input
								type={"text"}
								placeholder={`Search Store Categories`}
								value={Search}
								onChange={(e) => setSearch(e.target.value)}
								className="rounded form-control"
							/>
						</th>
						<td>
							<button
								className="btn btn-sm btn-primary"
								onClick={() => {
									setShowModal(true);
									setType("add");
									setStoreCat({ name: "", details: "" });
								}}
							>
								<PlusCircle /> Add Store Category
							</button>
						</td>
					</tr>
				</thead>
				{SearchStoreCat.length > 0 ? (
					<tbody>
						{SearchStoreCat.map((storeCat, index) => (
							<tr key={index} className="item">
								<td>
									{storeCat.name}{" "}
									{storeCat.live != 1 ? (
										<small
											className="text-success align-text-bottom text"
											style={{ fontSize: 8 }}
										>
											<br />
											<em>offline</em>
										</small>
									) : null}
								</td>
								<td>{storeCat.details}</td>
								<td>
									<button
										className="btn btn-xs btn-outline-info mx-1"
										onClick={() => {
											setShowModal(true);
											setType("edit");
											setStoreCat({ ...storeCat });
										}}
									>
										{" "}
										<PencilSquare style={{ cursor: "pointer" }} />
									</button>
									<button
										className="btn btn-xs btn-outline-danger mx-1"
										onClick={() => {
											setShowModal(true);
											setType("delete");
											setStoreCat({ ...storeCat });
										}}
									>
										{" "}
										<Trash style={{ cursor: "pointer" }} />
									</button>
								</td>
							</tr>
						))}
					</tbody>
				) : (
					<NoStoreCat></NoStoreCat>
				)}
			</table>
			<GeneratePdf id={"storecats"} name={"store-categories"}></GeneratePdf>
			<StoreCatCrud
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				storeCat={StoreCat}
				setStoreCat={setStoreCat}
				type={Type}
			></StoreCatCrud>
		</div>
	);
};

export default StoreCats;
