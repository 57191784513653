import React from "react";
import {
	Trash,
	PencilSquare,
	PlusCircle,
	PatchMinus,
} from "react-bootstrap-icons";

const ItemDisp = ({
	item,
	setShowModal,
	setType,
	setItem,
	Cats,
	setEntry,
	setEntryModal,
	setEntryType,
	Entries,
}) => {
	const FindCategory = (linker) => {
		let Cat = Cats.find((cat) => parseInt(cat.linker) === parseInt(linker));
		return Cat || { name: "unknown" };
	};

	return (
		<tr className="item">
			<td>
				{item.name}{" "}
				{item.live != 1 ? (
					<small
						className="text-success align-text-bottom text"
						style={{ fontSize: 8 }}
					>
						<br />
						<em>offline</em>
					</small>
				) : null}
			</td>
			<td>{item.details}</td>
			<td>
				{Entries.filter(
					(entry) => entry.type === "received" || entry.type === "return-issued"
				).reduce((a, b) => +a + +b.quantity, 0) -
					Entries.filter(
						(entry) =>
							entry.type === "issued" ||
							entry.type === "return-received" ||
							entry.type === "damaged"
					).reduce((a, b) => +a + +b.quantity, 0)}
			</td>
			<td>{FindCategory(item.catLinker).name}</td>
			<td>
				<PlusCircle
					onClick={() => {
						setEntryModal(true);
						setEntryType("add");
						setEntry({
							type: "",
							quantity: "",
							details: "",
							supplier: "",
							itemLinker: item.linker,
							live: 0,
							deleted: 0,
							trace: Date.now(),
							linker: Date.now(),
						});
						setItem({ ...item });
					}}
					style={{ cursor: "pointer" }}
					className="text-primary mx-2"
				/>
				<PatchMinus
					onClick={() => {
						setEntryModal(true);
						setEntryType("subtract");
						setEntry({
							type: "",
							quantity: "",
							details: "",
							supplier: "",
							itemLinker: item.linker,
							live: 0,
							deleted: 0,
							trace: Date.now(),
							linker: Date.now(),
						});
						setItem({ ...item });
					}}
					style={{ cursor: "pointer" }}
					className="text-primary mx-2"
				/>
				<PencilSquare
					onClick={() => {
						setShowModal(true);
						setType("edit");
						setItem({ ...item });
					}}
					style={{ cursor: "pointer" }}
					className="text-info mx-2"
				/>

				<Trash
					onClick={() => {
						setShowModal(true);
						setType("delete");
						setItem({ ...item });
					}}
					style={{ cursor: "pointer" }}
					className="text-danger mx-2"
				/>
			</td>
		</tr>
	);
};

export default ItemDisp;
