import { addBook, fetchBooks, updateBook } from "../reducers/BookSlice";
import { ApiUrl } from "./Urls";
import swal from "sweetalert";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchBooks = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB("books", (book) => book.instLinker == instLinker)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchBooks(data));
				FetchLiveBooks(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("books", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateBook(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLiveBooks = (token, instLinker, online, dispatch, books) => {
	fetch(`${ApiUrl}/book/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.books.map((onlineBook) => {
					let localBook = books.find(
						(item) => parseInt(item.linker) === parseInt(onlineBook.linker)
					);
					if (!localBook) {
						saveToIDB("books", onlineBook.linker, onlineBook);
						dispatch(updateBook(onlineBook));
					} else if (parseInt(localBook.trace) > parseInt(onlineBook.trace)) {
						updateLiveBook(localBook, token, dispatch);
					} else if (parseInt(localBook.trace) < parseInt(onlineBook.trace)) {
						saveToIDB("books", onlineBook.linker, onlineBook);
						dispatch(updateBook(onlineBook));
					}
				});

				books.map((book) => {
					if (!book.id) {
						addLiveBook(book, instLinker, token, dispatch);
					} else if (parseInt(book.live) === 0) {
						updateLiveBook(book, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLiveBook = (book, instLinker, token, dispatch) => {
	dispatch(updateBook({ ...book, live: 0 }));
	saveToIDB("books", book.linker, { ...book, live: 0 });
	fetch(`${ApiUrl}/book/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...book,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateBook(res.book));
				saveToIDB("books", res.book.linker, res.book);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLiveBook = (book, token, dispatch) => {
	dispatch(updateBook({ ...book, live: 0 }));
	saveToIDB("books", book.linker, { ...book, live: 0 });
	fetch(`${ApiUrl}/book/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...book,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateBook(res.book));
				saveToIDB("books", res.book.linker, res.book);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};

/*
export const FetchBooks = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveBooks(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updateBook(payload));
	}
};

export const addLiveBook = (book, instLinker, token, dispatch) => {
	return fetch(`${ApiUrl}/book/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...book,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateBook(res.book));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLiveBook = (book, token, dispatch) => {
	return fetch(`${ApiUrl}/book/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...book,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateBook(res.book));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLiveBooks = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/book/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchBooks(res.books));
			}
		})
		.catch(() => {});
};*/
