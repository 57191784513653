import React, { useState, useEffect } from "react";
import GradeCrud from "./GradeCrud";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, Trash, PencilSquare } from "react-bootstrap-icons";
import NoGrade from "./NoGrade";
import { FetchGrades } from "../../../Network/GradeApi";
import GeneratePdf from "../../../Utils/GeneratePdf";

const Grades = () => {
	useEffect(() => {
		FetchGrades(User.token, ActiveCred.instLinker, dispatch, online);
	}, []);

	const [ShowModal, setShowModal] = useState(false);
	const [Grade, setGrade] = useState({ min: "", max: "", grade: "" });
	const [Search, setSearch] = useState("");
	const [Type, setType] = useState("add");

	//redux dispatch
	const dispatch = useDispatch();

	const online = useSelector((state) => state.online.online);
	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Grades = useSelector((state) => state.grade.grades)
		.filter((grade) => parseInt(grade.deleted) === 0)
		.sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	return (
		<div>
			<p className="text-center h6 text-primary text-capitalize">
				Students Scores Grading System
			</p>
			<table className="table table-sm" id="grades">
				<thead className="thead-dark">
					<tr>
						<th scope="column">Grade</th>
						<th>Min</th>
						<th>Max</th>
						<td>
							<button
								className="btn btn-sm btn-primary"
								onClick={() => {
									setShowModal(true);
									setType("add");
									setGrade({ min: "", max: "", grade: "" });
								}}
							>
								<PlusCircle /> Add Grade
							</button>
						</td>
					</tr>
				</thead>
				{Grades.length > 0 ? (
					<tbody>
						{Grades.map((grade, index) => (
							<tr key={index} className="item">
								<td>
									{grade.grade}{" "}
									{grade.live != 1 ? (
										<small
											className="text-success align-text-bottom text"
											style={{ fontSize: 8 }}
										>
											<br />
											<em>offline</em>
										</small>
									) : null}
								</td>
								<td>{grade.min}</td>
								<td>{grade.max}</td>
								<td>
									<button
										className="btn btn-xs btn-outline-info mx-1"
										onClick={() => {
											setShowModal(true);
											setType("edit");
											setGrade({ ...grade });
										}}
									>
										{" "}
										<PencilSquare style={{ cursor: "pointer" }} />
									</button>
									<button
										className="btn btn-xs btn-outline-danger mx-1"
										onClick={() => {
											setShowModal(true);
											setType("delete");
											setGrade({ ...grade });
										}}
									>
										{" "}
										<Trash style={{ cursor: "pointer" }} />
									</button>
								</td>
							</tr>
						))}
					</tbody>
				) : (
					<NoGrade></NoGrade>
				)}
			</table>
			<GeneratePdf id={"grades"} name={"grading-system"}></GeneratePdf>
			<GradeCrud
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				grade={Grade}
				setGrade={setGrade}
				type={Type}
			></GradeCrud>
		</div>
	);
};

export default Grades;
