import swal from "sweetalert";
import { addSub, fetchSubs, updateSub } from "../reducers/SubSlice";
import { ApiUrl } from "./Urls";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchSubs = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB("subs", (sub) => sub.instLinker == instLinker)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchSubs(data));
				FetchLiveSubs(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("subs", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateSub(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLiveSubs = (token, instLinker, online, dispatch, subs) => {
	fetch(`${ApiUrl}/sub/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.subs.map((onlineSub) => {
					let localSub = subs.find(
						(item) => parseInt(item.linker) === parseInt(onlineSub.linker)
					);
					if (!localSub) {
						saveToIDB("subs", onlineSub.linker, onlineSub);
						dispatch(updateSub(onlineSub));
					} else if (parseInt(localSub.trace) > parseInt(onlineSub.trace)) {
						updateLiveSub(localSub, token, dispatch);
					} else if (parseInt(localSub.trace) < parseInt(onlineSub.trace)) {
						saveToIDB("subs", onlineSub.linker, onlineSub);
						dispatch(updateSub(onlineSub));
					}
				});

				subs.map((sub) => {
					if (!sub.id) {
						addLiveSub(sub, instLinker, token, dispatch);
					} else if (parseInt(sub.live) === 0) {
						updateLiveSub(sub, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLiveSub = (sub, instLinker, token, dispatch) => {
	dispatch(updateSub(sub));
	saveToIDB("subs", sub.linker, sub);
	fetch(`${ApiUrl}/sub/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...sub,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateSub(res.sub));
				saveToIDB("subs", res.sub.linker, res.sub);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLiveSub = (sub, token, dispatch) => {
	dispatch(updateSub(sub));
	saveToIDB("subs", sub.linker, sub);
	fetch(`${ApiUrl}/sub/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...sub,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateSub(res.sub));
				saveToIDB("subs", res.sub.linker, res.sub);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};

/*
export const FetchSubs = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveSubs(token, instLinker, online, dispatch);
	} else if (
		parseInt(instLinker) === parseInt(payload.instLinker) ||
		parseInt(payload.softwareId) ===
			parseInt(parseInt(`${instLinker}`.split("").reverse().join("")) / 1000)
	) {
		dispatch(updateSub(payload));
	}
};

export const addLiveSub = (sub, instLinker, token, dispatch) => {
	return fetch(`${ApiUrl}/sub/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...sub,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateSub(res.sub));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLiveSub = (sub, token, dispatch) => {
	return fetch(`${ApiUrl}/sub/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...sub,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateSub(res.sub));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLiveSubs = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/sub/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchSubs(res.subs));
			}
		})
		.catch(() => {});
};*/
