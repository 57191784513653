import React, { useState } from "react";
import { Button } from "react-bootstrap";
import {
	ArrowBarLeft,
	FilePdf,
	Trash,
	PencilSquare,
	FileEarmarkPlus,
} from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ExamCrud from "../Academics/Exams/ExamCrud";
import GeneratePdf from "../../Utils/GeneratePdf";
import LetterHead from "../../components/LetterHead";

const StudentAcademics = () => {
	const params = useParams();
	const Navigate = useNavigate();
	const Inst = useSelector((state) => state.inst.activeInst);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Student = useSelector((state) => state.cred.creds).find(
		(student) => parseInt(params.studentLinker) === parseInt(student.linker)
	) || { linker: params.studentLinker };
	const Structures = useSelector((state) => state.structure.structures);
	const Subjects = useSelector((state) => state.subject.subjects);
	const Activities = useSelector((state) => state.activity.activities);
	const Exams = useSelector((state) => state.exam.exams).filter(
		(exam) =>
			parseInt(exam.studentLinker) === parseInt(Student.linker) &&
			exam.deleted == "0"
	);
	const Levels = Structures.filter(
		(structure) =>
			structure.type === "level" && parseInt(structure.deleted) === 0
	);

	const Periods = Structures.filter(
		(structure) =>
			structure.type === "period" && parseInt(structure.deleted) === 0
	);

	const Faculties = Structures.filter(
		(structure) =>
			structure.type === "faculty" && parseInt(structure.deleted) === 0
	);

	const Years = Structures.filter(
		(structure) =>
			structure.type === "academic-year" && parseInt(structure.deleted) === 0
	);
	const Grades = useSelector((state) => state.grade.grades);

	const [ActiveExam, setActiveExam] = useState({ student: {} });

	const [ExamCrudModal, setExamCrudModal] = useState(false);
	const [Type, setType] = useState("edit");

	const Durations = [
		...new Set(
			Exams.map(
				(exam) =>
					`${Years.length > 0 ? exam.yearLinker : null}-${
						Faculties.length > 0 ? exam.facultyLinker : null
					}-${Levels.length > 0 ? exam.levelLinker : null}-${
						Periods.length > 0 ? exam.periodLinker : null
					}`
			)
		),
	];

	const GetStructure = (linker) => {
		let Structure = Structures.find(
			(structure) => parseInt(structure.linker) === parseInt(linker)
		);

		return Structure || {};
	};

	const GetSubject = (linker) => {
		let Subject = Subjects.find(
			(subject) => parseInt(subject.linker) === parseInt(linker)
		);

		return Subject || { name: "unknown" };
	};

	const GetActivity = (linker) => {
		let Activity = Activities.find(
			(activity) => parseInt(activity.linker) === parseInt(linker)
		);

		return Activity || { name: "unknown" };
	};

	const DurationSubjects = (year, faculty, level, period) => {
		let newSubjects = [
			...new Set(
				Exams.filter(
					(exam) =>
						(Years.length > 0
							? parseInt(exam.yearLinker) === parseInt(year)
							: true) &&
						(Faculties.length > 0
							? parseInt(exam.facultyLinker) === parseInt(faculty)
							: true) &&
						(Levels.length > 0
							? parseInt(exam.levelLinker) === parseInt(level)
							: true) &&
						(Periods.length > 0
							? parseInt(exam.periodLinker) === parseInt(period)
							: true)
				).map((exam) => exam.subjectLinker)
			),
		];

		return newSubjects;
	};

	const DurationExams = (year, faculty, level, period) =>
		Exams.filter(
			(exam) =>
				(Years.length > 0
					? parseInt(exam.yearLinker) === parseInt(year)
					: true) &&
				(Faculties.length > 0
					? parseInt(exam.facultyLinker) === parseInt(faculty)
					: true) &&
				(Levels.length > 0
					? parseInt(exam.levelLinker) === parseInt(level)
					: true) &&
				(Periods.length > 0
					? parseInt(exam.periodLinker) === parseInt(period)
					: true)
		);

	const SubjectExams = (subject) =>
		Exams.filter((exam) => parseInt(exam.subjectLinker) === parseInt(subject));

	const GetGrade = (score) => {
		for (const grade of Grades) {
			if (score >= grade.min && score <= grade.max) {
				return grade.grade;
			}
		}
		return "";
	};

	return (
		<>
			<div>
				<div className="d-flex justify-content-around mt-2">
					<button
						className="btn btn-sm btn-outline-secondary"
						type="submit"
						onClick={() => Navigate(-1)}
					>
						<ArrowBarLeft></ArrowBarLeft> Go Back
					</button>
					{parseInt(ActiveCred.admin) === 1 ||
					parseInt(ActiveCred.tutor) === 1 ? (
						<button
							className="btn btn-sm btn-outline-secondary"
							type="submit"
							onClick={() => {
								setExamCrudModal(true);
								setType("add");
								setActiveExam({
									student: Student,
									id: 0,
									activityLinker: "",
									subjectLinker: "",
									linker: Date.now(),
									periodLinker: "",
									levelLinker: "",
									facultyLinker: "",
									yearLinker: "",
									score: "",
									maxScore: "",
									comment: "",
									grade: "",
									status: 0,
									trace: Date.now(),
									live: 0,
									deleted: 0,
									createdAt: 0,
									updatedAt: 0,
								});
							}}
						>
							<FileEarmarkPlus></FileEarmarkPlus> Add Exam
						</button>
					) : null}
				</div>

				{Durations.length < 1 ? (
					<div>
						{" "}
						<p className="h5 text-center">
							{Student.studentReg} {Student.firstname} {Student.lastname}{" "}
							{Student.surname}
						</p>
						<p className="text-center">No Academics Records</p>
					</div>
				) : (
					Durations.map((duration) => (
						<div key={duration}>
							<div
								id={`${Student.studentReg} ${Student.firstname} ${
									Student.lastname
								}
          ${Student.surname}  ${GetStructure(duration.split("-")[0]).name} ${
									GetStructure(duration.split("-")[1]).name
								} ${GetStructure(duration.split("-")[2]).name} ${
									GetStructure(duration.split("-")[3]).name
								}`}
							>
								<LetterHead></LetterHead>
								<p className="h5 text-center text-capitalize">
									{Student.studentReg} {Student.firstname} {Student.lastname}{" "}
									{Student.surname}
								</p>
								<p className="h6 text-center">
									{GetStructure(duration.split("-")[0]).name}{" "}
									{GetStructure(duration.split("-")[1]).name}{" "}
									{GetStructure(duration.split("-")[2]).name}{" "}
									{GetStructure(duration.split("-")[3]).name}
								</p>
								<table className="table table-sm table-bordered">
									<thead>
										<tr>
											<th>Subject/Unit</th>
											<th>Activities</th>
											<th>Score%</th>
										</tr>
									</thead>
									<tbody>
										{DurationSubjects(
											Years.length > 0 ? duration.split("-")[0] : null,
											Faculties.length > 0 ? duration.split("-")[1] : null,
											Levels.length > 0 ? duration.split("-")[2] : null,
											Periods.length > 0 ? duration.split("-")[3] : null
										).map((subject, index) => (
											<tr key={index}>
												<th scope="row">{GetSubject(subject).name}</th>
												<td>
													<table className="table table-sm table-bordered table-responsive">
														<tbody>
															{SubjectExams(subject).map((exam, index) => (
																<tr key={index}>
																	<td>
																		{GetActivity(exam.activityLinker).name}
																		{exam.live != 1 ? (
																			<small
																				className="text-success align-text-bottom text"
																				style={{ fontSize: 8 }}
																			>
																				<br />
																				<em>offline</em>
																			</small>
																		) : null}
																	</td>
																	<td>{exam.score + `/` + exam.maxScore}</td>
																	<td>{exam.comment}</td>
																	<td>{exam.grade}</td>
																	{parseInt(ActiveCred.admin) === 1 ||
																	parseInt(ActiveCred.tutor) === 1 ? (
																		<td>
																			<PencilSquare
																				onClick={() => {
																					setExamCrudModal(true);
																					setType("edit");
																					setActiveExam({
																						...exam,
																						student: Student,
																					});
																				}}
																				style={{ cursor: "pointer" }}
																				className="text-info m-1"
																			/>
																			<Trash
																				onClick={() => {
																					setExamCrudModal(true);
																					setType("delete");
																					setActiveExam({
																						...exam,
																						student: Student,
																					});
																				}}
																				style={{ cursor: "pointer" }}
																				className="text-danger m-1"
																			/>
																		</td>
																	) : null}
																</tr>
															))}
														</tbody>
													</table>
												</td>
												<th>
													{`${parseInt(
														(SubjectExams(subject).reduce(
															(a, b) => +a + +b.score,
															0
														) *
															100) /
															SubjectExams(subject).reduce(
																(a, b) => +a + +b.maxScore,
																0
															)
													)}%`}{" "}
													<strong>
														{GetGrade(
															(SubjectExams(subject).reduce(
																(a, b) => +a + +b.score,
																0
															) *
																100) /
																SubjectExams(subject).reduce(
																	(a, b) => +a + +b.maxScore,
																	0
																)
														)}
													</strong>
												</th>
											</tr>
										))}
										<tr>
											<th colSpan={2}>Average Percentage</th>
											<th>
												{`${parseInt(
													(DurationExams(
														Years.length > 0 ? duration.split("-")[0] : null,
														Faculties.length > 0
															? duration.split("-")[1]
															: null,
														Levels.length > 0 ? duration.split("-")[2] : null,
														Periods.length > 0 ? duration.split("-")[3] : null
													).reduce((a, b) => +a + +b.score, 0) *
														100) /
														DurationExams(
															Years.length > 0 ? duration.split("-")[0] : null,
															Faculties.length > 0
																? duration.split("-")[1]
																: null,
															Levels.length > 0 ? duration.split("-")[2] : null,
															Periods.length > 0 ? duration.split("-")[3] : null
														).reduce((a, b) => +a + +b.maxScore, 0)
												)}%`}{" "}
												<strong>
													{GetGrade(
														(DurationExams(
															Years.length > 0 ? duration.split("-")[0] : null,
															Faculties.length > 0
																? duration.split("-")[1]
																: null,
															Levels.length > 0 ? duration.split("-")[2] : null,
															Periods.length > 0 ? duration.split("-")[3] : null
														).reduce((a, b) => +a + +b.score, 0) *
															100) /
															DurationExams(
																Years.length > 0
																	? duration.split("-")[0]
																	: null,
																Faculties.length > 0
																	? duration.split("-")[1]
																	: null,
																Levels.length > 0
																	? duration.split("-")[2]
																	: null,
																Periods.length > 0
																	? duration.split("-")[3]
																	: null
															).reduce((a, b) => +a + +b.maxScore, 0)
													)}
												</strong>
											</th>
										</tr>
									</tbody>
								</table>
							</div>
							<GeneratePdf
								name={`${Student.studentReg} ${Student.firstname} ${
									Student.lastname
								}
          ${Student.surname}  ${GetStructure(duration.split("-")[0]).name} ${
									GetStructure(duration.split("-")[1]).name
								} ${GetStructure(duration.split("-")[2]).name} ${
									GetStructure(duration.split("-")[3]).name
								} Academic Report`}
								id={`${Student.studentReg} ${Student.firstname} ${
									Student.lastname
								}
          ${Student.surname}  ${GetStructure(duration.split("-")[0]).name} ${
									GetStructure(duration.split("-")[1]).name
								} ${GetStructure(duration.split("-")[2]).name} ${
									GetStructure(duration.split("-")[3]).name
								}`}
							></GeneratePdf>
						</div>
					))
				)}
			</div>
			<ExamCrud
				type={Type}
				ExamCrudModal={ExamCrudModal}
				setExamCrudModal={setExamCrudModal}
				Exam={ActiveExam}
				setExam={setActiveExam}
			></ExamCrud>
		</>
	);
};

export default StudentAcademics;
