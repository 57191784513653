import swal from "sweetalert";
import {
	addActivity,
	fetchActivities,
	updateActivity,
} from "../reducers/ActivitySlice";
import { ApiUrl } from "./Urls";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchActivities = async (
	instLinker,
	token,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB(
		"activities",
		(activity) => activity.instLinker == instLinker
	)
		.then((data) => {
			console.log(data);
			if (from === "fetch") {
				dispatch(fetchActivities(data));
				FetchLiveActivities(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("activities", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateActivity(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLiveActivities = (
	token,
	instLinker,
	online,
	dispatch,
	activities
) => {
	fetch(`${ApiUrl}/activity/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.activities.map((onlineActivity) => {
					let localActivity = activities.find(
						(item) => parseInt(item.linker) === parseInt(onlineActivity.linker)
					);
					if (!localActivity) {
						saveToIDB("activities", onlineActivity.linker, onlineActivity);
						dispatch(updateActivity(onlineActivity));
					} else if (
						parseInt(localActivity.trace) > parseInt(onlineActivity.trace)
					) {
						updateLiveActivity(localActivity, token, dispatch);
					} else if (
						parseInt(localActivity.trace) < parseInt(onlineActivity.trace)
					) {
						saveToIDB("activities", onlineActivity.linker, onlineActivity);
						dispatch(updateActivity(onlineActivity));
					}
				});

				activities.map((activity) => {
					if (!activity.id) {
						addLiveActivity(activity, instLinker, token, dispatch);
					} else if (parseInt(activity.live) === 0) {
						updateLiveActivity(activity, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLiveActivity = (activity, instLinker, token, dispatch) => {
	dispatch(updateActivity({ ...activity, live: 0 }));
	saveToIDB("activities", activity.linker, { ...activity, live: 0 });
	fetch(`${ApiUrl}/activity/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...activity,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateActivity(res.activity));
				saveToIDB("activities", res.activity.linker, res.activity);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLiveActivity = (activity, token, dispatch) => {
	dispatch(updateActivity({ ...activity, live: 0 }));
	saveToIDB("activities", activity.linker, { ...activity, live: 0 });
	fetch(`${ApiUrl}/activity/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...activity,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateActivity(res.activity));
				saveToIDB("activities", res.activity.linker, res.activity);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};

/*export const FetchActivities = async (
  instLinker,
  token,
  dispatch,
  lastonline,
  from = "fetch",
  payload = {}
) => {
  if (from === "fetch") {
    FetchLiveActivities(token, instLinker, lastonline, dispatch);
  } else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
    dispatch(updateActivity(payload));
  }
};

export const addLiveActivity = (activity, instLinker, token, dispatch) => {
  return fetch(`${ApiUrl}/activity/add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...activity,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 201) {
        dispatch(updateActivity(res.activity));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const updateLiveActivity = (activity, token, dispatch) => {
  return fetch(`${ApiUrl}/activity/edit`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...activity,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(updateActivity(res.activity));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const FetchLiveActivities = (token, instLinker, online, dispatch) => {
  fetch(`${ApiUrl}/activity/get`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      online,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchActivities(res.activities));
      }
    })
    .catch(() => {});
};*/
