import swal from "sweetalert";
import {
	addInvoice,
	fetchInvoices,
	updateInvoice,
} from "../reducers/InvoiceSlice";
import { ApiUrl } from "./Urls";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchInvoices = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB("invoices", (invoice) => invoice.instLinker == instLinker)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchInvoices(data));
				FetchLiveInvoices(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("invoices", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateInvoice(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLiveInvoices = (
	token,
	instLinker,
	online,
	dispatch,
	invoices
) => {
	fetch(`${ApiUrl}/invoice/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.invoices.map((onlineInvoice) => {
					let localInvoice = invoices.find(
						(item) => parseInt(item.linker) === parseInt(onlineInvoice.linker)
					);
					if (!localInvoice) {
						saveToIDB("invoices", onlineInvoice.linker, onlineInvoice);
						dispatch(updateInvoice(onlineInvoice));
					} else if (
						parseInt(localInvoice.trace) > parseInt(onlineInvoice.trace)
					) {
						updateLiveInvoice(localInvoice, token, dispatch);
					} else if (
						parseInt(localInvoice.trace) < parseInt(onlineInvoice.trace)
					) {
						saveToIDB("invoices", onlineInvoice.linker, onlineInvoice);
						dispatch(updateInvoice(onlineInvoice));
					}
				});

				invoices.map((invoice) => {
					if (!invoice.id) {
						addLiveInvoice(invoice, instLinker, token, dispatch);
					} else if (parseInt(invoice.live) === 0) {
						updateLiveInvoice(invoice, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLiveInvoice = (invoice, instLinker, token, dispatch) => {
	dispatch(updateInvoice({ ...invoice, live: 0 }));
	saveToIDB("invoices", invoice.linker, { ...invoice, live: 0 });
	fetch(`${ApiUrl}/invoice/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...invoice,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateInvoice(res.invoice));
				saveToIDB("invoices", res.invoice.linker, res.invoice);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLiveInvoice = (invoice, token, dispatch) => {
	dispatch(updateInvoice({ ...invoice, live: 0 }));
	saveToIDB("invoices", invoice.linker, { ...invoice, live: 0 });
	fetch(`${ApiUrl}/invoice/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...invoice,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateInvoice(res.invoice));
				saveToIDB("invoices", res.invoice.linker, res.invoice);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};
/*
export const FetchInvoices = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveInvoices(token, instLinker, online, dispatch);
	} else {
		if (parseInt(instLinker) === parseInt(payload.instLinker)) {
			dispatch(updateInvoice(payload));
		}
	}
};

export const addLiveInvoice = (invoice, instLinker, token, dispatch) => {
	return fetch(`${ApiUrl}/invoice/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...invoice,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateInvoice(res.invoice));
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLiveInvoice = (invoice, token, dispatch) => {
	return fetch(`${ApiUrl}/invoice/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...invoice,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateInvoice(res.invoice));
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLiveInvoices = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/invoice/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchInvoices(res.invoices));
			}
		})
		.catch(() => {});
};
*/
