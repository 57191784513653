import swal from "sweetalert";
import {
	addInterval,
	fetchIntervals,
	updateInterval,
} from "../reducers/IntervalSlice";
import { ApiUrl } from "./Urls";

import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchIntervals = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB(
		"intervals",
		(interval) => interval.instLinker == instLinker
	)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchIntervals(data));
				FetchLiveIntervals(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("intervals", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateInterval(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLiveIntervals = (
	token,
	instLinker,
	online,
	dispatch,
	intervals
) => {
	fetch(`${ApiUrl}/interval/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.intervals.map((onlineInterval) => {
					let localInterval = intervals.find(
						(item) => parseInt(item.linker) === parseInt(onlineInterval.linker)
					);
					if (!localInterval) {
						saveToIDB("intervals", onlineInterval.linker, onlineInterval);
						dispatch(updateInterval(onlineInterval));
					} else if (
						parseInt(localInterval.trace) > parseInt(onlineInterval.trace)
					) {
						updateLiveInterval(localInterval, token, dispatch);
					} else if (
						parseInt(localInterval.trace) < parseInt(onlineInterval.trace)
					) {
						saveToIDB("intervals", onlineInterval.linker, onlineInterval);
						dispatch(updateInterval(onlineInterval));
					}
				});

				intervals.map((interval) => {
					if (!interval.id) {
						addLiveInterval(interval, instLinker, token, dispatch);
					} else if (parseInt(interval.live) === 0) {
						updateLiveInterval(interval, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLiveInterval = (interval, instLinker, token, dispatch) => {
	dispatch(updateInterval({ ...interval, live: 0 }));
	saveToIDB("intervals", interval.linker, { ...interval, live: 0 });
	fetch(`${ApiUrl}/interval/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...interval,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateInterval(res.interval));
				saveToIDB("intervals", res.interval.linker, res.interval);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLiveInterval = (interval, token, dispatch) => {
	dispatch(updateInterval({ ...interval, live: 0 }));
	saveToIDB("intervals", interval.linker, { ...interval, live: 0 });
	fetch(`${ApiUrl}/interval/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...interval,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateInterval(res.interval));
				saveToIDB("intervals", res.interval.linker, res.interval);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};

/*
export const FetchIntervals = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveIntervals(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updateInterval(payload));
	}
};

export const addLiveInterval = (interval, instLinker, token, dispatch) => {
	return fetch(`${ApiUrl}/interval/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...interval,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateInterval(res.interval));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLiveInterval = (interval, token, dispatch) => {
	return fetch(`${ApiUrl}/interval/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...interval,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateInterval(res.interval));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLiveIntervals = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/interval/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchIntervals(res.intervals));
			}
		})
		.catch(() => {});
};*/
