import swal from "sweetalert";
import { addGrade, fetchGrades, updateGrade } from "../reducers/GradeSlice";
import { ApiUrl } from "./Urls";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchGrades = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB("grades", (grade) => grade.instLinker == instLinker)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchGrades(data));
				FetchLiveGrades(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("grades", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateGrade(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLiveGrades = (
	token,
	instLinker,
	online,
	dispatch,
	grades
) => {
	fetch(`${ApiUrl}/grade/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.grades.map((onlineGrade) => {
					let localGrade = grades.find(
						(item) => parseInt(item.linker) === parseInt(onlineGrade.linker)
					);
					if (!localGrade) {
						saveToIDB("grades", onlineGrade.linker, onlineGrade);
						dispatch(updateGrade(onlineGrade));
					} else if (parseInt(localGrade.trace) > parseInt(onlineGrade.trace)) {
						updateLiveGrade(localGrade, token, dispatch);
					} else if (parseInt(localGrade.trace) < parseInt(onlineGrade.trace)) {
						saveToIDB("grades", onlineGrade.linker, onlineGrade);
						dispatch(updateGrade(onlineGrade));
					}
				});

				grades.map((grade) => {
					if (!grade.id) {
						addLiveGrade(grade, instLinker, token, dispatch);
					} else if (parseInt(grade.live) === 0) {
						updateLiveGrade(grade, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLiveGrade = (grade, instLinker, token, dispatch) => {
	dispatch(updateGrade({ ...grade, live: 0 }));
	saveToIDB("grades", grade.linker, { ...grade, live: 0 });
	fetch(`${ApiUrl}/grade/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...grade,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateGrade(res.grade));
				saveToIDB("grades", res.grade.linker, res.grade);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLiveGrade = (grade, token, dispatch) => {
	dispatch(updateGrade({ ...grade, live: 0 }));
	saveToIDB("grades", grade.linker, { ...grade, live: 0 });
	fetch(`${ApiUrl}/grade/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...grade,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateGrade(res.grade));
				saveToIDB("grades", res.grade.linker, res.grade);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};

/*
export const FetchGrades = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveGrades(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updateGrade(payload));
	}
};

export const addLiveGrade = (grade, instLinker, token, dispatch) => {
	return fetch(`${ApiUrl}/grade/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...grade,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateGrade(res.grade));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLiveGrade = (grade, token, dispatch) => {
	return fetch(`${ApiUrl}/grade/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...grade,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateGrade(res.grade));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLiveGrades = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/grade/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchGrades(res.grades));
			}
		})
		.catch(() => {});
};*/
