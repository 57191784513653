import swal from "sweetalert";
import {
	addStructure,
	fetchStructures,
	updateStructure,
} from "../reducers/StructureSlice";
import { ApiUrl } from "./Urls";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchLocalStuctures = async (
	instLinker,
	token,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB(
		"structures",
		(structure) => structure.instLinker == instLinker
	)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchStructures(data));
				FetchLiveStructures(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("structures", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateStructure(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLiveStructures = (
	token,
	instLinker,
	online,
	dispatch,
	structures
) => {
	fetch(`${ApiUrl}/structure/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.structures.map((onlineStructure) => {
					let localStructure = structures.find(
						(item) => parseInt(item.linker) === parseInt(onlineStructure.linker)
					);
					if (!localStructure) {
						saveToIDB("structures", onlineStructure.linker, onlineStructure);
						dispatch(addStructure(onlineStructure));
					} else if (
						parseInt(localStructure.trace) > parseInt(onlineStructure.trace)
					) {
						updateLiveStructure(localStructure, token, dispatch);
					} else if (
						parseInt(localStructure.trace) < parseInt(onlineStructure.trace)
					) {
						saveToIDB("structures", onlineStructure.linker, onlineStructure);
						dispatch(updateStructure(onlineStructure));
					}
				});

				structures.map((structure) => {
					if (!structure.id) {
						addLiveStructure(structure, instLinker, token, dispatch);
					} else if (parseInt(structure.live) === 0) {
						updateLiveStructure(structure, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLiveStructure = (structure, instLinker, token, dispatch) => {
	dispatch(updateStructure({ ...structure, live: 0 }));
	saveToIDB("structures", structure.linker, { ...structure, live: 0 });
	fetch(`${ApiUrl}/structure/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...structure,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateStructure(res.structure));
				saveToIDB("structures", res.structure.linker, res.structure);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLiveStructure = (structure, token, dispatch) => {
	dispatch(updateStructure({ ...structure, live: 0 }));
	saveToIDB("structures", structure.linker, { ...structure, live: 0 });
	fetch(`${ApiUrl}/structure/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...structure,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateStructure(res.structure));
				saveToIDB("structures", res.structure.linker, res.structure);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};
/*
export const FetchLocalStuctures = async (
	instLinker,
	token,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveStructures(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updateStructure(payload));
	}
};
 
export const FetchLiveStructures = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/structure/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchStructures(res.structures));
			}
		})
		.catch(() => {});
};

export const addLiveStructure = (structure, instLinker, token, dispatch) => {
	return fetch(`${ApiUrl}/structure/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...structure,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateStructure(res.structure));
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLiveStructure = (structure, token, dispatch) => {
	return fetch(`${ApiUrl}/structure/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...structure,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateStructure(res.structure));
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};*/
