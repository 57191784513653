import swal from "sweetalert";
import { addItem, fetchItems, updateItem } from "../reducers/ItemSlice";
import { ApiUrl } from "./Urls";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchItems = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB(
		"storeitems",
		(storeItem) => storeItem.instLinker == instLinker
	)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchItems(data));
				FetchLiveItems(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("storeitems", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateItem(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLiveItems = (
	token,
	instLinker,
	online,
	dispatch,
	storeItems
) => {
	fetch(`${ApiUrl}/store-items/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.storeItems.map((onlineStoreItem) => {
					let localStoreItem = storeItems.find(
						(item) => parseInt(item.linker) === parseInt(onlineStoreItem.linker)
					);
					if (!localStoreItem) {
						saveToIDB("storeitems", onlineStoreItem.linker, onlineStoreItem);
						dispatch(updateItem(onlineStoreItem));
					} else if (
						parseInt(localStoreItem.trace) > parseInt(onlineStoreItem.trace)
					) {
						updateLiveItem(localStoreItem, token, dispatch);
					} else if (
						parseInt(localStoreItem.trace) < parseInt(onlineStoreItem.trace)
					) {
						saveToIDB("storeitems", onlineStoreItem.linker, onlineStoreItem);
						dispatch(updateItem(onlineStoreItem));
					}
				});

				storeItems.map((storeItem) => {
					if (!storeItem.id) {
						addLiveItem(storeItem, instLinker, token, dispatch);
					} else if (parseInt(storeItem.live) === 0) {
						updateLiveItem(storeItem, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLiveItem = (storeItem, instLinker, token, dispatch) => {
	dispatch(updateItem({ ...storeItem, live: 0 }));
	saveToIDB("storeitems", storeItem.linker, { ...storeItem, live: 0 });
	fetch(`${ApiUrl}/store-items/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...storeItem,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateItem(res.storeItem));
				saveToIDB("storeitems", res.storeItem.linker, res.storeItem);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLiveItem = (storeItem, token, dispatch) => {
	dispatch(updateItem({ ...storeItem, live: 0 }));
	saveToIDB("storeitems", storeItem.linker, { ...storeItem, live: 0 });
	fetch(`${ApiUrl}/store-items/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...storeItem,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateItem(res.storeItem));
				saveToIDB("storeitems", res.storeItem.linker, res.storeItem);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};

/*export const FetchItems = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveItems(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updateItem(payload));
	}
};

export const addLiveItem = (item, instLinker, token, dispatch) => {
	return fetch(`${ApiUrl}/store-items/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...item,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateItem(res.storeItem));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLiveItem = (item, token, dispatch) => {
	return fetch(`${ApiUrl}/store-items/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...item,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateItem(res.storeItem));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLiveItems = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/store-items/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchItems(res.storeItems));
			}
		})
		.catch(() => {});
};*/
