import React from "react";
import { Trash, PencilSquare } from "react-bootstrap-icons";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";

const Interval = ({ interval, setShowModal, setType, setInterval }) => {
	const ActiveCred = useSelector((state) => state.cred.activeCred);

	return (
		<Dropdown className="m-0 p-0">
			<Dropdown.Toggle
				variant="transparent"
				className="m-0 p-0 text-start text-wrap"
			>
				<strong>
					<small>
						{interval.startAt}-{interval.endAt}{" "}
						{interval.live != 1 ? (
							<small
								className="text-success align-text-bottom text"
								style={{ fontSize: 8 }}
							>
								<br />
								<em>offline</em>
							</small>
						) : null}
					</small>
				</strong>
			</Dropdown.Toggle>
			{parseInt(ActiveCred.admin) === 1 ? (
				<Dropdown.Menu>
					<Dropdown.Item>
						<div className="d-flex justify-content-around">
							<PencilSquare
								onClick={() => {
									setShowModal(true);
									setType("edit");
									setInterval({ ...interval });
								}}
								style={{ cursor: "pointer" }}
								className="text-info mx-2"
							/>
							<Trash
								onClick={() => {
									setShowModal(true);
									setType("delete");
									setInterval({ ...interval });
								}}
								style={{ cursor: "pointer" }}
								className="text-danger mx-2"
							/>
						</div>
					</Dropdown.Item>
				</Dropdown.Menu>
			) : null}
		</Dropdown>
	);
};

export default Interval;
