import React from "react";
import image from "../../assets/images/school-management-tech-system.png";
import student from "../../assets/images/school-management-tech-system-student.jpg";
import schoolclass from "../../assets/images/school-management-tech-system-class.jpg";
import staff from "../../assets/images/school-management-tech-system-staff.jpg";
import exam from "../../assets/images/school-management-tech-system-exam.jpg";
import unit from "../../assets/images/school-management-tech-system-units.jpg";
import fee from "../../assets/images/school-management-tech-system-fee.jpg";
import library from "../../assets/images/school-management-tech-system-library.jpg";
import inventory from "../../assets/images/school-management-tech-system-inventory.jpg";
import expenses from "../../assets/images/school-management-tech-system-expenses.jpg";
import timetable from "../../assets/images/school-management-tech-system-timetable.jpg";
import AuthModal from "../Auth/AuthModal";
import Header from "../../components/Header";
import PwaInstall from "../../Utils/PwaInstall";

const Home = () => {
	return (
		<div
			style={{
				minHeight: "100vh",
				minWidth: "100vw",

				backgroundImage: `url(${image})`,
				backgroundSize: "cover",
				backgroundRepeat: "repeat-y",
				backgroundPosition: "center",
			}}
		>
			<Header></Header>
			<div
				style={{
					marginTop: "40px",
					backgroundColor: "rgba(64, 99, 112, 0.5)",
					minHeight: "100vh",
					minWidth: "100vw",
				}}
			>
				<div style={{ minHeight: "10vh" }}>
					<p className="text-light text-center h5 bg-dark">
						Become A World Class Manager And Administrator
					</p>
				</div>

				<div
					style={{
						backgroundColor: "rgba(255, 255, 255)",
						borderRadius: "20%",
						padding: "2vh",
					}}
				>
					{" "}
					<p className="text-center h6">
						Eliminate Stress Of Tedious Sheets And Paper Work
					</p>{" "}
					<AuthModal></AuthModal>
					<div className="d-flex justify-content-center mt-1">
						<a
							download
							href="https://dl.dropbox.com/scl/fi/ze7b5zeqsbu44weee96wj/School-Techsystem-7.0.zip?rlkey=grjt8temaz6u28809edru7c8b&st=jkwqbtd2&dl=0"
						>
							<button className="btn btn-secondary rounded-pill">
								Download Software (Offline & Online)
							</button>
						</a>
						<PwaInstall title={"(Offline & Online Only)"}></PwaInstall>
					</div>
				</div>
				<div className="d-flex justify-content-center">
					<iframe
						width="560"
						height="315"
						src="https://www.youtube.com/embed/Tn81LZLFTeU?si=jw6aO9NNO_uqG1WZ"
						title="SCHOOL OR COLLEGE SOFTWARE MANAGEMENT SYSTEM"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen
					></iframe>
				</div>
				<div className="row bg-transparent d-flex justify-content-around m-2">
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Staff Access</p>
							</div>
							<img
								className="card-img-top"
								src={staff}
								alt="school-management-tech-system-staff"
							/>
							<div className="mx-1">
								<p className="card-text">
									Improve institution connectivity by adding staffs and manage
									their access levels from Admin, Accounts, Academics and
									Library.
								</p>
								<p className="card-text">
									Staffs Can use the email you register them with to access
									their portal
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Student Records</p>
							</div>
							<img
								className="card-img-top"
								src={student}
								alt="school-management-tech-system-student"
							/>
							<div className="mx-1 pt-2">
								<p className="card-text"></p>
								<p className="card-text"></p>
								<p className="card-text">
									Manage student records with ease helps you to add and filter
									students by class and be able to view all students information
									from one place
								</p>
								<p className="card-text"></p>
								<p className="card-text"></p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Class Manager</p>
							</div>
							<img
								className="card-img-top"
								src={schoolclass}
								alt="school-management-tech-system-class"
							/>
							<p className="card-text"></p>
							<p className="card-text"></p>
							<div className="mx-1">
								<p className="card-text">
									Enjoy maximum flexibility by adding your institution classes
									and academic years helping you to filter students according to
									class
								</p>
								<p className="card-text"></p>
								<p className="card-text"></p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Class Units/Subjects</p>
							</div>
							<img
								className="card-img-top"
								src={unit}
								alt="school-management-tech-system-unit"
							/>
							<div className="mx-1 py-5">
								<p className="card-text">
									Add each class units or subjects for ease of Assessments
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Exams And Assessments</p>
							</div>
							<img
								className="card-img-top"
								src={exam}
								alt="school-management-tech-system-exam"
							/>
							<div className="mx-1 py-5">
								<p className="card-text">
									Add your students assessment scores and the system will
									agragate and also provide class ranking
								</p>
								<p className="card-text">
									If online students and guardians can view from their portals
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Fee Invoices And Payments</p>
							</div>
							<img
								className="card-img-top"
								src={fee}
								alt="school-management-tech-system-fee"
							/>
							<div className="mx-1">
								<ul>
									<li>
										Invoice Students various fee from tuituion to accomodation
									</li>
									<li>Class Bulk invoice also available to ease your work</li>{" "}
									<li>Fee payment is simple and direct</li>
									<li>Fee Balances which can be filtred by class with total</li>
									<li>Each student Fee Statement and downloadable as pdf</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Timetable</p>
							</div>
							<img
								className="card-img-top"
								src={timetable}
								alt="school-management-tech-system-timetable"
							/>
							<div className="mx-1 py-5">
								<p className="card-text">
									Manage your Timetable with ease by adding class durations and
									tutors for each class
								</p>
								<p className="card-text">
									You can filter the timetable with class ensuring clarity
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Library</p>
							</div>
							<img
								className="card-img-top"
								src={library}
								alt="school-management-tech-system-library"
							/>{" "}
							<div className="mx-1 py-4">
								<p className="card-text">
									Avoid learning material losses using library manage
								</p>
								<p className="card-text">
									Be able to view if a book is in the library or issued and onto
									whom
								</p>
								<p className="card-text">
									Add books categories, add books, issue books and accept
									returned books
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Inventory</p>
							</div>
							<img
								className="card-img-top"
								src={inventory}
								alt="school-management-tech-system-inventory"
							/>
							<div className="mx-1 py-4">
								<p className="card-text">
									Don't Loose materials by using inventory manager
								</p>
								<p className="card-text">
									Be able to view inventory when they are received and to whom
									they are issued and when
								</p>
								<p className="card-text">
									Add inventory categories for easier management
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Expenses</p>
							</div>
							<img
								className="card-img-top"
								src={expenses}
								alt="school-management-tech-system-expenses"
							/>
							<div className="mx-1 py-4">
								<p className="card-text">
									Manage expenses like a proffessional
								</p>
								<p className="card-text">
									View when and who incurred a specific expense and total
									summary by month or year
								</p>
								<p className="card-text">
									Add expense categories to see which expenses are highly
									incurred
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
