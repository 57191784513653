import swal from "sweetalert";
import {
	addExpense,
	fetchExpenses,
	updateExpense,
} from "../reducers/ExpenseSlice";
import { ApiUrl } from "./Urls";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchExpenses = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB("expenses", (expense) => expense.instLinker == instLinker)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchExpenses(data));
				FetchLiveExpenses(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("expenses", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateExpense(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLiveExpenses = (
	token,
	instLinker,
	online,
	dispatch,
	expenses
) => {
	fetch(`${ApiUrl}/expense/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.expenses.map((onlineExpense) => {
					let localExpense = expenses.find(
						(item) => parseInt(item.linker) === parseInt(onlineExpense.linker)
					);
					if (!localExpense) {
						saveToIDB("expenses", onlineExpense.linker, onlineExpense);
						dispatch(updateExpense(onlineExpense));
					} else if (
						parseInt(localExpense.trace) > parseInt(onlineExpense.trace)
					) {
						updateLiveExpense(localExpense, token, dispatch);
					} else if (
						parseInt(localExpense.trace) < parseInt(onlineExpense.trace)
					) {
						saveToIDB("expenses", onlineExpense.linker, onlineExpense);
						dispatch(updateExpense(onlineExpense));
					}
				});

				expenses.map((expense) => {
					if (!expense.id) {
						addLiveExpense(expense, instLinker, token, dispatch);
					} else if (parseInt(expense.live) === 0) {
						updateLiveExpense(expense, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLiveExpense = (expense, instLinker, token, dispatch) => {
	dispatch(updateExpense({ ...expense, live: 0 }));
	saveToIDB("expenses", expense.linker, { ...expense, live: 0 });
	fetch(`${ApiUrl}/expense/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...expense,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateExpense(res.expense));
				saveToIDB("expenses", res.expense.linker, res.expense);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLiveExpense = (expense, token, dispatch) => {
	dispatch(updateExpense({ ...expense, live: 0 }));
	saveToIDB("expenses", expense.linker, { ...expense, live: 0 });
	fetch(`${ApiUrl}/expense/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...expense,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateExpense(res.expense));
				saveToIDB("expenses", res.expense.linker, res.expense);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};
/*
export const FetchExpenses = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveExpenses(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updateExpense(payload));
	}
};

export const addLiveExpense = (expense, instLinker, token, dispatch) => {
	return fetch(`${ApiUrl}/expense/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...expense,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateExpense(res.expense));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLiveExpense = (expense, token, dispatch) => {
	return fetch(`${ApiUrl}/expense/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...expense,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateExpense(res.expense));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLiveExpenses = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/expense/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchExpenses(res.expenses));
			}
		})
		.catch(() => {});
};*/
